<template>
    <v-container class="pa-0">
        <v-row class="mb-4">
            <v-col
                v-for="(pdc, index) in store.predefined"
                :cols="isDesktop ? 12 : 12"
                :key="index"
            >
                <app-selectable-card
                    @on-selected="onPredefinedSelected(pdc)"
                    :is-selected="isPredefinedSelected(pdc)"
                    :title="pdc.title"
                    min-width="250"
                    class="w-100 pdc-trigger"
                    :key="pdc.id"
                    :disabled="isPredefinedDisabled(pdc)"
                />
                <v-tooltip
                    v-if="isPredefinedDisabled(pdc)"
                    activator="parent"
                    location="top left"
                    content-class="bg-white text-neutral-black text-paragraph-md pa-5 elevation-3"
                    offset="-5"
                >
                    This challenge is already in your challenge paths. You can edit it from there.
                </v-tooltip>
            </v-col>

            <v-col cols="12" v-for="(item, index) in store.customChallenges" :key="index">
                <app-selectable-card
                    @on-selected="() => onRemovedCustom(item)"
                    :is-selected="true"
                    :title="item.description"
                    @title-change="(title: string) => (item.description = title)"
                    :editable="true"
                    min-width="250"
                    class="mb-4 w-100 pdc-trigger"
                    :disabled="isCustomDisabled(item)"
                >
                </app-selectable-card>
                <v-tooltip
                    v-if="isCustomDisabled(item)"
                    activator="parent"
                    location="top left"
                    content-class="bg-white text-neutral-black text-paragraph-md pa-5 elevation-3"
                    offset="-5"
                >
                    This challenge is already in your challenge paths. You can edit it from there.
                </v-tooltip>
            </v-col>
        </v-row>

        <v-row no-gutters>
            <FreeTextInput :value="model" @update:model-value="onValueChange" />
        </v-row>

        <v-row no-gutters justify="start">
            <app-plus-button @click="onAddNew">Add my own</app-plus-button>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import FreeTextInput from '@/components/common/FreeTextInput.vue';
    import { isSameChallenge, useCurrentChallengeStore } from '@/store/current-challenges/store';
    import { ref, toValue } from 'vue';
    import type { PredefinedChallenge } from '@/api/types/predefinedChallenge';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import type { EditableCurrentChallenge } from '@/store/current-challenges/types';
    import { challengeSequencingRoute } from '@/router/routes';

    const model = ref('');

    const { isDesktop } = useIsDesktop();

    const emit = defineEmits(['see-list']);

    const store = useCurrentChallengeStore();

    const onValueChange = (value: string) => {
        model.value = value;
    };
    const onAddNew = () => {
        const text = toValue(model);
        if (text) {
            store.onAddChallenge(text);
            model.value = '';
        }
    };

    const onRemovedCustom = (item: EditableCurrentChallenge) => {
        store.onRemove(item);
    };

    const onPredefinedSelected = (pdc: PredefinedChallenge) => {
        store.togglePredefined(pdc);
    };

    const isPredefinedSelected = (pdc: PredefinedChallenge) => {
        return store.predefinedSelected.includes(pdc);
    };

    // const onNewSelected = (pdc: PredefinedChallenge) => {
    //     store.toggleSelected(pdc);
    // };

    const isPredefinedDisabled = (pdc: PredefinedChallenge) => {
        if (isPredefinedSelected(pdc)) {
            const challenge = store.selected.find((c) => isSameChallenge(pdc, c));
            const hasPlannedActions = challenge?.actions.length;
            return !!hasPlannedActions;
        }
    };

    const sequenceRouteForPredefined = (pdc: PredefinedChallenge) => {
        const found = store.selected.find((c) => isSameChallenge(pdc, c));
        return found?.id ? challengeSequencingRoute(found.id) : null;
    };

    const isCustomDisabled = (item: EditableCurrentChallenge) => {
        return item.actions.length > 0;
    };
</script>

<style>
    .pdc-trigger:hover .hidden-pdc-desc {
        display: block;
    }
</style>
