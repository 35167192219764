<template>
    <div class="d-block position-relative">
        <span v-if="isDesktop" class="story-uvp-card-background-image-1-desktop d-print-none">
            <StoryUniqueValuePropositionImage1 />
        </span>
        <span v-if="isDesktop" class="story-uvp-card-background-image-2-desktop d-print-none">
            <StoryUniqueValuePropositionImage2 />
        </span>

        <StoryCard id="story-uvp" class="story-uvp-card" :min-height="hasStatement ? 200 : 160">
            <template #title> My superpower</template>

            <template #description>
                Everyone is unique and brings something special to the workplace. Based on what
                you've told us, we believe this is your superpower.
            </template>

            <template #content>
                <div v-if="hasStatement">
                    <span
                        class="text-misc-story text-custom-neutral-black print-story-uvp-card-statement"
                        :class="responsiveClass"
                    >
                        {{ formattedStatement }}
                    </span>
                    <StorySectionStatementRegenerate
                        v-if="showFeedback && !isFeedbackUpdating"
                        @click="onFeedback"
                    />
                </div>
                <StorySectionStatementInProgress
                    v-else-if="
                        store.uvp?.state === StoryStatementState.New ||
                        store.uvp?.state === StoryStatementState.Progress
                    "
                >
                </StorySectionStatementInProgress>
            </template>
        </StoryCard>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { format } from 'date-fns';
    import { useStoryStore } from '@/store/story/store';
    import StorySectionStatementRegenerate from '@/components/story/section/statement/StorySectionStatementRegenerate.vue';
    import { StorySectionId } from '@/store/story/types';
    import { verify } from '@/store/verify';
    import { StoryStatementState } from '@/api/types/canvas/apiStory';
    import StorySectionStatementInProgress from '@/components/story/section/statement/StorySectionStatementInProgress.vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import StoryUniqueValuePropositionImage1 from '@/components/story/section/activity/content/uvp/StoryUniqueValuePropositionImage1.vue';
    import StoryUniqueValuePropositionImage2 from '@/components/story/section/activity/content/uvp/StoryUniqueValuePropositionImage2.vue';
    import StoryCard from '@/components/story/section/StoryCard.vue';

    const store = useStoryStore();

    const { isDesktop, responsiveClass } = useIsDesktop();

    const isFeedbackUpdating = ref(false);
    const onFeedback = async (value: boolean) => {
        try {
            isFeedbackUpdating.value = true;
            const uvp = verify(store.uvp, 'UVP not found');
            await store.saveFeedback(StorySectionId.UVP, uvp, value);
        } finally {
            isFeedbackUpdating.value = false;
        }
    };

    const formattedStatement = computed(() => {
        const uvp = store.uvp;
        if (!uvp) {
            return '';
        }

        if (!uvp?.content) {
            return '';
        }
        console.log('FULL UVP WORKING::');
        const rawStatement = uvp.content;
        console.log(rawStatement);
        //The following formatting should ideally be done by the backend but keeping here for now to demonstrate working
        const adjustedStatement = rawStatement.replace('Step 3: ', '').replace('Step 4: ', '');
        const uvpStatement = adjustedStatement
            .substring(adjustedStatement.lastIndexOf('\n') + 1)
            .trim();
        console.log('UVP ONLY::');
        console.log(uvpStatement);
        return uvpStatement;
    });

    const lastUpdatedDate = computed(() => {
        if (!store.uvp) {
            return '';
        }

        return format(new Date(store.uvp?.created_at), 'dd/MM/yy hh:mm:ss a');
    });

    const showFeedback = computed(() => {
        return (store.uvp?.feedback === null || !store.uvp?.feedback) && !isFeedbackUpdating.value;
    });

    const hasStatement = computed(() => {
        return store.uvp?.content !== '' && store.uvp?.state === StoryStatementState.Completed;
    });
</script>

<style lang="scss">
    .story-uvp-card {
        overflow: visible !important;
        background: linear-gradient(94.29deg, #ffc32b 0%, #fb8625 100%) !important;
    }

    .story-uvp-card-background-image-1-desktop {
        position: absolute;
        top: -30px;
        left: -30px;
    }

    .story-uvp-card-background-image-2-desktop {
        position: absolute;
        bottom: -52px;
        right: -30px;
    }
</style>
