import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { useTheme } from 'vuetify';
import { watch } from 'vue';
import { DEFAULT_THEME } from '@/plugins/vuetify';

export const useDynamicTheme = defineStore('theme', () => {
    const route = useRoute();
    const theme = useTheme();

    /**
     * Function to update the theme color in the status bar (meta tag)
     * Note: This meta tag is used by mobile browsers to update the status bar color
     */
    const updateThemeColor = (color: string) => {
        document.querySelector('meta[name="theme-color"]')?.setAttribute('content', color);
    };

    /**
     *  Watch for theme changes from route meta and apply it immediately
     */
    watch(
        () => route.meta.theme,
        (newTheme = DEFAULT_THEME) => {
            if (newTheme !== theme.global.name.value) {
                // 1. Updates the theme globally.
                theme.global.name.value = newTheme as string;

                // 2. At this point, the theme object is already updated
                const newPrimaryColor = theme.current.value.colors.primary;
                updateThemeColor(newPrimaryColor);
            }
        },
        { immediate: true }, // Ensures theme updates immediately when the store initializes
    );

    return {
        theme,
    };
});
