<template>
    <v-card
        variant="outlined"
        :width="props.width"
        :min-width="props.width"
        :height="props.height"
        class="personal-values-card pa-5 d-flex flex-column border-2-grey-200 rounded-8"
        :class="formattedCategory"
    >
        <div class="pa-0 d-flex justify-center">
            <v-icon size="48" class="card-icon">
                {{ icon }}
            </v-icon>
        </div>

        <v-btn
            v-if="props.removable"
            icon="mdi-close"
            class="remove-icon"
            variant="plain"
            size="medium"
            @click="$emit('removed')"
        />

        <v-spacer />

        <div class="text-custom-neutral-grey-900 justify-center text-center">
            <div class="text-capitalize text-heading-h1 mb-2">
                {{ props.value.title }}
            </div>
            <div class="mb-1 text-paragraph-md">
                {{ props.value.description }}
            </div>
        </div>

        <v-spacer />

        <v-card-actions class="pl-0 pt-1 w-100 justify-center">
            <v-chip
                label
                class="card-category text-caption-subtitle text-uppercase font-weight-600"
            >
                <span class="text-use-heading-font">{{ formattedCategory }}</span>
            </v-chip>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import { type PersonalValue } from '@/api/types/personalValue';
    import { computed } from 'vue';
    import {
        getCategoryIcon,
        standardCategory,
    } from '@/components/canvas/personal-values/upload/util';

    const props = withDefaults(
        defineProps<{
            main?: boolean;
            width?: number | string;
            height?: number;
            value: PersonalValue;
            removable?: boolean;
            hideDescription?: boolean;
        }>(),
        { main: true },
    );

    const formattedCategory = computed(() => {
        return standardCategory(props.value.category);
    });

    const icon = computed(() => {
        return getCategoryIcon(props.value.category);
    });
</script>

<style scoped lang="scss">
    .remove-icon {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .independence {
        --color: rgb(var(--v-theme-custom-independence-value));
    }

    .achievement {
        --color: rgb(var(--v-theme-custom-achievement-value));
    }

    .empathy {
        --color: rgb(var(--v-theme-custom-empathy-value));
    }

    .adventure {
        --color: rgb(var(--v-theme-custom-adventure-value));
    }

    .creativity {
        --color: rgb(var(--v-theme-custom-creativity-value));
    }

    .harmony {
        --color: rgb(var(--v-theme-custom-harmony-value));
    }

    .personal-values-card {
        .card-icon {
            color: var(--color);
        }

        .card-category {
            color: #262626;
            background: var(--color);

            :deep(.v-chip__content) {
                color: #262626;
                mix-blend-mode: overlay;
            }
        }
    }
</style>
