<template>
    <AppGuideModal :model-value="modelValue" @update:modelValue="emitModelValue">
        <template #title>
            <div class="text-heading-h2 font-weight-regular">
                Awesome work finishing
                <span class="font-weight-700">{{ feedback?.action?.title?.toLowerCase() }}</span
                >!
            </div>
        </template>
        <template #default>
            <div>
                I’d love to know how you found it so that we can learn what kinds of activities work
                best for you!
            </div>

            <div class="mt-4">
                <v-rating
                    v-model="feedback!.rating"
                    :max="5"
                    :step="0.5"
                    :readonly="false"
                    half-increments
                    hover
                    :size="isDesktop ? 'x-large' : 'default'"
                    color="primary"
                    :rules="[requiredRule('Current Company Career Confidence')]"
                    :hide-details="false"
                    label="How would you rate this activity?"
                    class="border-1-custom-neutral-grey-400 rounded-4 w-100 px-0"
                ></v-rating>
            </div>

            <!-- Helpful checkbox using v-checkbox -->
            <div class="mt-4">
                <div class="text-paragraph-xs font-weight-bold mb-2">
                    Was this activity helpful?
                </div>
                <app-button
                    height="50"
                    override-css="px-6 py-3"
                    prepend-icon="mdi-thumb-up-outline"
                    variant="flat"
                    class="mr-2"
                    :color="feedback!.helpful ? 'primary' : 'secondary'"
                    @click="feedback!.helpful = true"
                >
                    <span class="text-paragraph-sm font-weight-600">Yes</span>
                </app-button>
                <app-button
                    height="50"
                    override-css="px-6 py-3"
                    prepend-icon="mdi-thumb-down-outline"
                    variant="flat"
                    :color="!feedback!.helpful ? 'primary' : 'secondary'"
                    @click="feedback!.helpful = false"
                    ><span class="text-paragraph-sm font-weight-600">No</span>
                </app-button>
            </div>

            <!-- Textarea for Description using v-textarea -->
            <div class="mt-4">
                <v-textarea
                    v-model="feedback!.description"
                    label="Reflect on what you learned by doing this"
                    color="white"
                    auto-grow
                    variant="outlined"
                ></v-textarea>
            </div>

            <div>
                <v-checkbox
                    class="border-1-custom-neutral-grey-400 rounded-4"
                    v-model="feedback!.add_to_learned_experience"
                    label="Add to my memorable experiences"
                    color="primary"
                ></v-checkbox>
            </div>
        </template>

        <template #actions>
            <app-notification
                v-if="isError"
                class="rounded-4 text-paragraph-md font-weight-600"
                title="There was an error saving feedback"
                variant="error"
            />
            <v-row no-gutters class="mt-4 w-100 justify-center">
                <app-button
                    color="primary"
                    @click="saveFeedback"
                    height="48"
                    class="text-paragraph-sm"
                    border="secondary"
                    variant="flat"
                    :loading="isSaving"
                    :disabled="isSaving"
                    >Submit
                </app-button>
            </v-row>
        </template>
    </AppGuideModal>
</template>

<script lang="ts" setup>
    import { requiredRule } from '@/components/profile/rules';
    import { storeToRefs } from 'pinia';
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { ref } from 'vue';
    import { verify } from '@/store/verify';

    // Props
    defineProps<{
        modelValue: boolean;
    }>();

    // Emit function
    const emit = defineEmits(['update:modelValue', 'feedback-save']);

    function emitModelValue(value: boolean) {
        emit('update:modelValue', value);
    }

    const { isDesktop } = useIsDesktop();

    const store = useSingleChallengePathStore();
    const { feedback } = storeToRefs(store);

    const closeDialog = () => {
        emitModelValue(false);
    };

    const isSaving = ref(false);
    const isError = ref(false);

    const saveFeedback = async () => {
        try {
            isError.value = false;
            isSaving.value = true;

            const value = verify(feedback.value, 'feedback invalid');
            await store.saveActivityFeedback(value); // Save the feedback

            isSaving.value = false;

            closeDialog(); // Close the dialog after saving

            if (store.isCompleted) {
                emit('feedback-save');
            }
        } catch (e) {
            isError.value = true;
        }
    };
</script>
