<template>
    <v-container class="home-section" variant="flat" fluid :class="type">
        <v-container class="home-section__heading pa-0">
            <v-img :src="imageText" width="624" height="203" />
            <v-img :src="image" width="202" height="202" />
        </v-container>

        <v-container class="home-section__about text-center">
            <p>{{ aboutDescription }}</p>
        </v-container>

        <v-container class="home-section__content w-100 pa-0">
            <v-row class="ga-5" no-gutters>
                <v-col class="d-flex" v-for="(processCard, index) in sectionItems">
                    <HomeSectionItem
                        :type="type"
                        :key="index"
                        :icon="processCard.icon"
                        :title="processCard.title"
                        :description="processCard.description"
                    />
                </v-col>
            </v-row>
        </v-container>

        <v-container class="pa-0 justify-center d-flex">
            <app-button size="large" :to="to" :color="buttonColor" variant="flat" :disabled="disabled" >
                {{ submitText }}
            </app-button>
        </v-container>
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import HomeSectionItem from '@/components/home/HomeSectionItem.vue';
    import type { RouteLocationRaw } from 'vue-router';
    import { computed } from 'vue';

    const { isDesktop, responsiveClass } = useIsDesktop();

    type SectionCardItem = {
        icon: string;
        title: string;
        description: string;
    };

    const props = defineProps<{
        type: 'story' | 'challenges' | 'growth';
        imageText: string;
        image: string;
        aboutDescription: string;
        to: RouteLocationRaw;
        submitText: string;
        sectionItems: SectionCardItem[];
        disabled?: boolean;
    }>();

    const buttonColor = computed(() => {
        switch (props.type) {
            case 'story':
                return 'story-button';
            case 'challenges':
                return 'challenges-button';
            case 'growth':
                return 'growth-button';
        }
    })
</script>

<style scoped lang="scss">
    .home-section {
        display: flex;
        padding: 64px 80px;
        flex-direction: column;
        align-items: center;
        gap: 48px;
        align-self: stretch;
        border-radius: 32px;

        &.story {
            background: rgb(var(--v-theme-story-primary-light));
        }

        &.challenges {
            background: rgb(var(--v-theme-challenges-primary-light));
        }

        &.growth {
            background:  rgb(var(--v-theme-growth-primary-light));
        }
    }

    .home-section__heading {
        display: flex;
        max-width: 880px;
        align-items: center;
        gap: 32px;
    }
</style>
