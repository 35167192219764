<template>
    <app-tutorial section-name="Future Aspirations" action-text="Let's go" :steps="2">
        <template #title="{ step }">
            <span v-if="step === 1">What does success look like?</span>
            <span v-else-if="step === 2">Visualise your future</span>
        </template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    Whilst none of us can predict the future with any certainty, the old saying is
                    true - if you don't know where you are going any road won't get you there!
                </p>
                <p>
                    We want to help you visualise yourself in the future - and construct a plan to
                    help you get there.
                </p>
            </div>
            <div v-else-if="step === 2">
                <p>
                    What do you imagine yourself doing in say - 5 - or even 10 years? What do you
                    want your career to be like - how will it create the lifestyle you want?
                </p>
            </div>
        </template>
    </app-tutorial>
</template>
