<template>
    <v-dialog v-model="show" max-width="640">
        <v-card class="pa-6">
            <v-card-title class="px-0 text-heading-h3 text-wrap"
                >Archive <span class="font-weight-700">{{ item.description }}</span> challenge
            </v-card-title>
            <v-card-text class="px-0 text-paragraph-md">
                Are you sure you want to archive this challenge?
            </v-card-text>
            <v-card-actions class="px-0">
                <app-button @click="onCancel">Cancel</app-button>
                <v-spacer />
                <app-button color="primary" variant="flat" @click="onArchiveClick"
                    >Yes, archive
                </app-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { useRouter } from 'vue-router';
    import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';

    const props = defineProps<{
        modelValue: boolean;
        item: CanvasCurrentChallenge;
    }>();

    const emit = defineEmits(['update:modelValue']);
    const show = ref(false);
    const router = useRouter();

    // Watch external value and sync local state
    watch(
        () => props.modelValue,
        (newVal) => {
            show.value = newVal;
        },
        { immediate: true },
    );

    // Update parent when local state changes
    watch(
        () => show.value,
        (newVal) => {
            emit('update:modelValue', newVal);
        },
    );

    const onCancel = () => {
        show.value = false;
    };

    const store = useSingleChallengePathStore();
    const challengePathStore = useChallengePathStore();
    const isDeleting = ref(false);

    const navigationStore = useNavigationStore();

    const onArchiveClick = async () => {
        try {
            isDeleting.value = true;
            await store.deactivateChallenge();

            // reload store to retrieve latest challenge status
            await challengePathStore.load();

            await navigationStore.setTabByKey('select-challenge');
        } finally {
            isDeleting.value = true;
            show.value = false;
        }
    };
</script>
