<template>
    <StoryCard color="primary-light">
        <template #title> Challenges</template>

        <template #description>
            From our research, we know people like you experience many similar challenges. We've
            listed some of the most common ones here for you. Feel free to add your own, and select
            as many as you'd like to work on
        </template>

        <template #content>
            <v-container class="pa-0 bg-primary-light" v-if="store.activeChallenges.length">
                <v-card variant="flat" class="pa-0" color="primary-light">
                    <v-card-text class="pa-0">
                        <v-card
                            v-for="(item, index) in store.activeChallenges"
                            :key="index"
                            :item="item"
                            min-width="260"
                            class="rounded-0 rounded-lg challenge-item border-2-grey-200"
                            :class="index > 0 ? 'mt-2' : ''"
                            elevation="0"
                        >
                            <v-card-item class="w-100 pa-5">
                                <div class="text-heading-h4">{{ item.description }}</div>

                                <template v-if="isDesktop" #append>
                                    <ChallengesHomeItemPathwayInProgressChip
                                        v-if="isInProgress(item) && !isCompleted(item)"
                                    />
                                    <ChallengesHomeItemPathwayCompletedChip
                                        v-else-if="isCompleted(item)"
                                    />
                                </template>
                                <div v-if="!isDesktop && item.actions.length">
                                    <ChallengesHomeItemPathwayInProgressChip
                                        v-if="isInProgress(item) && !isCompleted(item)"
                                    />
                                    <ChallengesHomeItemPathwayCompletedChip
                                        v-else-if="isCompleted(item)"
                                    />
                                </div>
                            </v-card-item>
                        </v-card>
                    </v-card-text>

                    <v-card-actions class="pa-0 mt-6">
                        <app-button
                            color="primary"
                            variant="flat"
                            :size="isDesktop ? 'large' : 'small'"
                            :to="challengeActivityRoute"
                            >Add more
                        </app-button>
                    </v-card-actions>
                </v-card>
            </v-container>
        </template>
    </StoryCard>
</template>

<script setup lang="ts">
    import StoryCard from '@/components/story/section/StoryCard.vue';
    import { useCurrentChallengeStore } from '@/store/current-challenges/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { challengeActivityRoute } from '@/router/routes';
    import type { EditableCurrentChallenge } from '@/store/current-challenges/types';
    import ChallengesHomeItemPathwayInProgressChip from '@/components/pathway/home/ChallengesHomeItemPathwayInProgressChip.vue';
    import ChallengesHomeItemPathwayCompletedChip from '@/components/pathway/home/ChallengesHomeItemPathwayCompletedChip.vue';
    import {
        type CurrentChallengeAction,
        CurrentChallengeActionState,
    } from '@/api/types/plan/currentChallengeAction';

    const store = useCurrentChallengeStore();

    const { isDesktop } = useIsDesktop();

    const isInProgress = function (item: EditableCurrentChallenge) {
        return item.actions.length > 0;
    };

    const isCompleted = function (item: EditableCurrentChallenge) {
        return (
            item.actions.length > 0 &&
            item.actions.every((action: CurrentChallengeAction) => {
                return action.state === CurrentChallengeActionState.Completed;
            })
        );
    };
</script>
