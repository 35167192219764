<template>
    <PersonalValueSelectedItem
        :value="props.selection.personalValue"
        :sub-type="subType"
        @on-remove="onRemoveItem"
    />
</template>
<script setup lang="ts">
    import { usePersonalValuesStore } from '@/store/personal-values/store';
    import type { PersonalValueSelection } from '@/store/personal-values/types';
    import PersonalValueSelectedItem from '@/components/canvas/personal-values/upload/PersonalValueSelectedItem.vue';
    import { useGuideStore } from '@/store/guide/store';
    import { PersonalValueSelectionSubType } from '@/api/types/canvas/personalValue';

    const props = defineProps<{
        selection: PersonalValueSelection;
        subType: PersonalValueSelectionSubType;
    }>();

    const store = usePersonalValuesStore();
    const guideStore = useGuideStore();

    const onRemoveItem = () => {
        store.onSubTypeRemoved(props.selection);
        guideStore.onUserPersonalSubTypeSelection(props.selection.personalValue, null);
    };
</script>
