import { getGuideChats, type GuideChat, useAdminGuideWebsocket } from '@/services/admin/guide/api';
import {
    ASSISTANT,
    GuideEventResponseMessageType,
    GuideEventType,
    type GuideMessage,
    type GuideMessageData,
    type GuideMessageEvent,
    GuideMessagePriority,
} from '@/store/guide/types';
import { v4 as uuidv4 } from 'uuid';
import { getMessageEvent } from '@/store/guide/util';
import { formatDistanceToNow } from 'date-fns';
import { defineStore } from 'pinia';
import { sendEvent } from '@/services/guide/guideClient';
import { useUsersStore } from '@/store/user/store';
import { verify } from '@/store/verify';
import type { UseWebSocketReturn } from '@vueuse/core';

type RoomParticipant = { _id: number | string; username: string; status: { state: string } };
type Room = { roomId: number; roomName: string; users: RoomParticipant[] };
export type AdminGuideStoreState = {
    afterFetchCb: null | (() => void);
    websockets: { [key: number]: UseWebSocketReturn<any> };
    guideChats: GuideChat[];
    currentGuideChatId: number | null;
    messages: { [key: number]: GuideMessage[] };
    messagesLoaded: boolean;
    accessToken: string;
    roomsLoading: boolean;
    roomsLoaded: boolean;
};
interface UsersPerRoom {
    [key: number]: RoomParticipant[]; // Using the room ID as key
}

export const useAdminGuideStore = defineStore('admin-guide-store', {
    state: (): AdminGuideStoreState => ({
        websockets: {},
        afterFetchCb: null,
        guideChats: [],
        currentGuideChatId: null,
        messages: {},
        messagesLoaded: false,
        roomsLoading: false,
        roomsLoaded: false,
        accessToken: '',
    }),

    getters: {
        usersPerRoom(state): UsersPerRoom {
            return state.guideChats.reduce((usersPerRoom, guideChat) => {
                const adminUser = verify(useUsersStore().user, 'No user');

                const userId = guideChat.user.id;
                const username = guideChat.user.first_name;

                // Ensure the accumulator object for this room exists
                if (!usersPerRoom[guideChat.id]) {
                    usersPerRoom[guideChat.id] = [];
                }

                // Append users to the room
                usersPerRoom[guideChat.id].push(
                    { _id: ASSISTANT.id, username: ASSISTANT.username, status: { state: 'online' } },
                    { _id: userId!, username: username!, status: { state: 'online' } },
                    {
                        _id: adminUser.id,
                        username: adminUser.first_name || 'admin',
                        status: { state: 'online' },
                    },
                );

                return usersPerRoom;
            }, {} as UsersPerRoom); // Initial value for the accumulator is an empty object
        },

        rooms(): Room[] {
            const rooms = this.guideChats.map((guideChat) => ({
                roomId: guideChat.id,
                roomName: `${guideChat.user.first_name} at ${guideChat.screen}`,
                users: this.usersPerRoom[guideChat.id],
            }));
            return rooms;
        },

        currentGuideChatMesages(): GuideMessage[] {
            if (!this.currentGuideChatId) {
                return [];
            }

            this.messagesLoaded = false;
            const currentMessages = this.messages[this.currentGuideChatId];
            this.messagesLoaded = true;

            return currentMessages;
        },
    },

    actions: {
        async loadGuideChats(accessToken: string) {
            // TODO: auto load rooms

            this.roomsLoading = true;
            this.accessToken = accessToken;

            const guideChats = await getGuideChats(accessToken);

            if (guideChats.data) {
                this.guideChats = guideChats.data;
                console.log('GuideChats loaded');
                this.roomsLoading = false;
                this.roomsLoaded = true;
            }
        },

        username(chat_id: number, sender_id: string): string | undefined {
            const participant = this.usersPerRoom[chat_id]?.find((p) => p._id == sender_id);
            return participant?.username;
        },

        async sendMessage(message: { content: string }): Promise<void> {
            const userAdmin = verify(useUsersStore().user, 'No user');

            if (!this.currentGuideChatId) {
                return;
            }

            const guideMessage: GuideMessageData = {
                id: uuidv4(),
                guide_message_id: null,
                chat_id: this.currentGuideChatId,
                content: message.content,
                type: GuideEventResponseMessageType.Text,
                role: 'assistant',
                created_at: new Date(),
                sender_id: userAdmin.id.toString(),
                history: false,
                priority: GuideMessagePriority.Normal,
            };

            const messageEvent: GuideMessageEvent = {
                uuid: uuidv4(),
                type: GuideEventType.Message,
                value: guideMessage,
            };

            sendEvent(this.websockets[this.currentGuideChatId], messageEvent);
        },

        _addMessage(chatId: number, messageEvent: GuideMessageEvent) {
            const message = messageEvent.value;
            if (!message) {
                return;
            }

            const chatMessage = {
                _id: message.id,
                content: message.content,
                senderId: message.sender_id,
                username: this.username(chatId, message.sender_id),
                date: formatDistanceToNow(new Date(message.created_at), {
                    addSuffix: true,
                    includeSeconds: true,
                }),
                timestamp: new Date(message.created_at).toString().substring(16, 21),
                ctx: messageEvent,
                animate: !message.history,
                seen: message.history,
            };
            console.log('New message', chatId, chatMessage);

            this.messages[chatId] = [...this.messages[chatId], chatMessage];
        },

        async closeCurrentWebsocket() {
            if (this.currentGuideChatId && this.websockets[this.currentGuideChatId]) {
                console.log('Closing websocket', this.currentGuideChatId);
                this.websockets[this.currentGuideChatId].close(1000);
                this.messages[this.currentGuideChatId] = [];
            }
        },

        async fetchMessages({ room }: { room: { roomId: number } }) {
            const chatId = room.roomId;
            if (this.currentGuideChatId == chatId) {
                return;
            }

            this.messagesLoaded = false;
            this.closeCurrentWebsocket();
            this.currentGuideChatId = chatId;
            this.connectToChat(chatId);
        },

        async connectToChat(chatId: number) {
            if (this.websockets[chatId]) {
                // when websocket is already created, open it again
                this.websockets[chatId].open();
                return;
            }
            const onConnected = () => {
                console.log('WS opened...', chatId);
            };

            const onDisconnected = (ws: WebSocket, event: CloseEvent): void => {
                console.log('WS disconnected...', chatId);
            };

            const onError = (ws: WebSocket, event: Event): void => {
                console.error('WS on error...', chatId);
            };

            const onFailed = (): void => {
                console.log('WS on failed after retries...', chatId);
            };

            const onMessage = (ws: WebSocket, event: MessageEvent): void => {
                const data = event.data;
                console.debug('WS Message received', data);
                if (data) {
                    if (data === 'pong') {
                        console.debug('WS still alive...');
                        return;
                    }

                    console.debug(`WS data received: {data}`);

                    const eventShowMessage = getMessageEvent(data);
                    if (eventShowMessage) {
                        this._addMessage(chatId, eventShowMessage);
                    }
                }
                console.log('WS on message...');
            };

            console.log('Connecting to GuideChat: ', chatId);

            this.messages[chatId] = [];

            this.websockets[chatId] = useAdminGuideWebsocket(chatId, this.accessToken, {
                onConnected,
                onDisconnected,
                onError,
                onMessage,
                onFailed,
            });
        },
    },
});
