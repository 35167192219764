<template>
    <v-container class="fill-height pa-0 ma-0" fluid>
        <v-row class="fill-height w-100" no-gutters justify="start">
            <v-col class="fill-height" align-self="start" cols="12">
                <v-container class="fill-height d-flex flex-column rounded-0 pa-0" fluid>
                    <slot />

                    <v-row class="w-100" no-gutters>
                        <v-col cols="12">
                            <!-- Each tab might load data on init, so we add a loader while that happens -->
                            <Suspense timeout="150" suspensible>
                                <template #fallback>
                                    <app-global-loader class="fill-height" />
                                </template>
                                <template #default>
                                    <slot :name="currentTab" />
                                </template>
                            </Suspense>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { computed } from 'vue';

    const navigationStore = useNavigationStore();

    const currentTab = computed(() => {
        return navigationStore.item?.key ?? navigationStore.items[0].key;
    });
</script>
