<template>
    <v-btn
        :height="height"
        :color="color"
        :max-width="maxWidth"
        :slim="false"
        :to="props.to"
        :prepend-icon="prependIcon"
        :append-icon="appendIcon"
        :disabled="disabled"
        :loading="loading"
        @click="emit('click')"
        class="text-none opacity-1"
        :variant="variant"
        :class="cssClass"
        :size="responsiveSize"
    >
        <slot></slot>
    </v-btn>
</template>

<script setup lang="ts">
    import type { RouteLocationRaw } from 'vue-router';
    import { computed } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const emit = defineEmits(['click']);

    /**
     * The main button component for the application.
     * Note that the size of the button is usually determined by the device type, so you only need to
     * override the height or size properties in special cases.
     *
     * Usually:
     * - in desktop we use medium (a.k.a. vuetify 'default')
     * - in mobile we use small
     */
    const props = withDefaults(
        defineProps<{
            hideCursor?: boolean;
            /**
             * If height is provided, it will take priority over the size property.
             * If height is not provided, it will be determined by the size property.
             */
            height?: number | string;
            /**
             * If size is not provided, it will be determined by the device type:
             * - desktop: default
             * - mobile: small
             */
            size?: NonNullable<'x-small' | 'small' | 'default' | 'large' | 'x-large'> | undefined;
            color?: string;
            to?: RouteLocationRaw;
            prependIcon?: string;
            appendIcon?: string;
            disabled?: boolean;
            loading?: boolean;
            maxWidth?: number | string;
            variant?: NonNullable<'outlined' | 'flat' | 'elevated' | 'tonal' | 'plain'> | undefined;
            weight?: NonNullable<
                | 'light'
                | 'regular'
                | 'medium'
                | 'bold'
                | 'black'
                | 'thin'
                | '600'
                | '700'
                | undefined
            >;
            borderColor?:
                | 'neutral-black'
                | 'secondary-blue'
                | 'secondary-pink'
                | 'secondary-purple';
            overrideCss?: string;
        }>(),
        {
            variant: 'outlined',
            weight: '600',
            maxWidth: '100%',
            overrideCss: '',
            hideCursor: false,
        },
    );

    const { isDesktop, responsiveClass } = useIsDesktop();

    const cssClass = computed(() => {
        return {
            [`font-weight-${props.weight}`]: props.weight,
            [`border-color-${props.borderColor}`]: props.borderColor,
            [props.overrideCss]: !!props.overrideCss,
            ['cursor-not-allowed']: props.disabled,
            ['cursor-pointer']: !props.disabled,
            [responsiveClass.value]: true,
        };
    });

    const responsiveSize = computed((): string => {
        if (props.size) {
            return props.size;
        }

        if (isDesktop.value) {
            return 'default';
        } else {
            return 'small';
        }
    });
</script>

<style scoped lang="scss">
    @import '@/styles/global/typography_mixin.scss';

    a.v-btn,
    button {
        line-height: 24px;
        letter-spacing: normal;
    }

    .text-white {
        --border-color: white;
    }

    .border-color-neutral-black {
        --border-color: rgb(var(--v-theme-custom-neutral-black));
    }

    .border-color-secondary-blue {
        --border-color: rgb(var(--v-theme-secondary-blue));
    }

    .border-color-secondary-pink {
        --border-color: rgb(var(--v-theme-secondary-pink));
    }

    .border-color-secondary-purple {
        --border-color: rgb(var(--v-theme-secondary-purple));
    }

    button.v-btn--variant-outlined {
        border: 1px solid var(--border-color, rgb(var(--v-theme-custom-neutral-black))) !important;
    }

    :deep(.v-btn__content) {
        @include text-paragraph-sm;
        font-weight: 600;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    // Override height and padding for different sizes
    // These classes are added by vuetify, but are override.
    .v-btn:not(.fill-height) {
        &.v-btn--size-small {
            height: 32px;
            padding: 6px 12px;
        }

        &.v-btn--size-default {
            height: 38px;
            padding: 8px 16px;
        }

        &.v-btn--size-large {
            height: 46px;
            padding: 12px 24px;
        }

        &.v-btn--size-x-large {
            height: 54px;
            padding: 12px 32px;
        }
    }
</style>
