<template>
    <ProfileCard title="Location & Residency">
        <v-autocomplete
            label="NZ Region"
            variant="outlined"
            :items="nzRegionOptions"
            :return-object="false"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            v-model="userInputs.location"
            :rules="[requiredRule('NZ Region')]"
            class="mb-2"
            item-title="title"
            item-value="value"
            validate-on="input"
        />

        <v-autocomplete
            label="Visa Status"
            variant="outlined"
            :chips="true"
            :auto-select-first="true"
            :clearable="true"
            :items="visaOptions"
            v-model="userInputs.visa_type"
            :rules="[requiredRule('Visa')]"
            :return-object="false"
            item-title="title"
            item-value="value"
            validate-on="input"
        />
    </ProfileCard>
</template>

<script setup lang="ts">
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';
    import { nzRegionOptions, visaOptions } from '@/components/profile/userInputOptions';
    import { requiredRule } from '@/components/profile/rules';
    import ProfileCard from '@/components/profile/ProfileCard.vue';

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);
</script>
