<template>
    <v-card elevation="0 rounded-16 px-10 py-8 w-100" min-height="120" :to="profileRoute">
        <v-card-item class="pa-0">
            <div class="pb-2 text-heading-h2">
                {{ userStore.displayName }}, your profile is
                <span v-if="profileStore.profileCompletion === 100"> complete </span>
                <span v-else>
                    {{ formatPercentage(profileStore.profileCompletion) }} complete
                </span>
            </div>

            <template v-slot:append>
                <app-fa-icon icon="fas fa-arrow-right" size="2x" />
            </template>
        </v-card-item>
        <v-progress-linear
            :model-value="profileStore.profileCompletion"
            color="white"
            bg-opacity="0.2"
            class="w-100 mt-2"
            height="6"
            :rounded="true"
        ></v-progress-linear>
    </v-card>
</template>

<script setup lang="ts">
    import { useUserProfileStore } from '@/store/profile/store';
    import { formatPercentage } from '@/lib/formatPercentage';
    import { useUsersStore } from '@/store/user/store';
    import { profileRoute } from '@/router/routes';

    const profileStore = useUserProfileStore();
    const userStore = useUsersStore();
</script>

<style scoped lang="scss">
    .challenge-progress-bar {
        background: var(
            --Primary-Yellow-gradient,
            linear-gradient(167deg, #ffc32b 0%, #fb8625 100%)
        );
    }

    .v-card {
        border-radius: 16px;
        background: var(
            --Primary-Global-Yellow-Gradient,
            linear-gradient(180deg, #ffda7c 0%, #ffc32c 100%)
        );
    }
</style>
