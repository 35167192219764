<template>
    <HomeSection
        :disabled="true"
        type="growth"
        :about-description="aboutDescription"
        :image-text="imageText"
        :image="image"
        :section-items="sectionItems"
        :to="homeRoute"
        submit-text="Coming soon"
    />
</template>

<script setup lang="ts">
    import imageText from '@/assets/images/home/growth-text.svg';
    import image from '@/assets/images/story/personality.svg';

    import HomeSection from '@/components/home/HomeSection.vue';
    import { homeRoute } from '@/router/routes';

    const aboutDescription = 'Growth is how we help you choose a future career pathway & jobs most suited to your needs.';

    const sectionItems = [
        {
            icon: 'search',
            title: 'Vision & Goals',
            description:
                'Getting clear on the future you are trying to design, takes a clear vision and needs statement. We help you make the hard trade-offs and have clarity on what you most want.',
        },
        {
            icon: 'arrow-up-right-dots',
            title: 'Pathways & Jobs',
            description:
                'There are many ways to work now, we help you choose a pathway first, and then show you what possible jobs might suit you in that path, so you can make good decisions.',
        },
        {
            icon: 'compass',
            title: 'Action plan',
            description:
                'Transitioning and growing in your career takes work. We make it easy by showing you the steps to take to close skill & experience gaps.',
        },
    ];
</script>
