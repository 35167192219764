<template>
    <StoryCard color="primary-light">
        <template #title>Pathways</template>

        <template #description>
            Based on what we know about you, we will help you solve your Challenges by curating a
            set of activities to help you. We call these Pathways. We will also direct you to
            relevant internal & external resources to help you get started.
        </template>

        <template #content>
            <v-container class="pa-0 bg-primary-light" v-if="store.inProgressChallenges.length">
                <div class="text-heading-h3">In progress</div>
                <v-card variant="flat" class="pa-0" color="primary-light">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.inProgressChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            @update="onUpdateInProgressClick(item)"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0 mt-8 bg-primary-light" v-if="store.completedChallenges.length">
                <div class="text-heading-h3">Completed</div>
                <v-card variant="flat" class="pa-0" color="primary-light">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.completedChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <div class="pa-0 mt-6 w-100">
                <app-button
                    color="primary"
                    variant="flat"
                    :size="isDesktop ? 'large' : 'small'"
                    :to="challengePathwaysRoute"
                    >View all pathways
                </app-button>
            </div>
        </template>
    </StoryCard>
</template>

<script setup lang="ts">
    import StoryCard from '@/components/story/section/StoryCard.vue';
    import PathwayChallengeListItem from '@/components/pathway/PathwayChallengeListItem.vue';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { challengePathwaysRoute } from '@/router/routes';
    import { useRouter } from 'vue-router';

    const store = useChallengePathStore();
    const { isDesktop } = useIsDesktop();

    const navigationStore = useNavigationStore();

    const router = useRouter();

    const onUpdateInProgressClick = (item: CanvasCurrentChallenge) => {
        // Navigating to the progress tab of the challenge with a challenge in context
        // Order matters here, first set the context then navigate to the progress tab
        //
        // Code smell: This should be only one action.
        // The path should include maybe the challenge in context in the query params or in a path param?
        navigationStore.setContext({ item });
        router.push({ ...challengePathwaysRoute, query: { tab: 'progress' } });
    };
</script>
