import { type App as Application } from 'vue';
import AppButton from '@/components/buttons/AppButton.vue';
import AppPlusButton from '@/components/buttons/AppPlusButton.vue';
import GuideChatDialog from '@/components/guide/chat/GuideChatDialog.vue';
import GuideChatActivator from '@/components/guide/chat/GuideChatActivator.vue';
import AppGuideInline from '@/components/guide/inline/AppGuideInline.vue';
import AppNotification from '@/components/common/AppNotification.vue';
import AppTutorial from '@/components/guide/AppTutorial.vue';
import AppButtonIcon from '@/components/buttons/AppButtonIcon.vue';
import AppActivityLayout from '@/components/common/app-page/AppPageContent.vue';
import AppGuideModal from '@/components/guide/modal/AppGuideModal.vue';
import AppSortingControls from '@/components/common/AppSortingControls.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import AppPage from '@/components/common/app-page/AppPage.vue';
import AppPageFooter from '@/components/common/app-page/AppPageFooter.vue';
import AppPageHeader from '@/components/common/app-page/AppPageHeader.vue';
import AppPageHeaderProgress from '@/components/common/app-page/AppPageHeaderProgress.vue';
import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
import AppLabel from '@/components/common/AppLabel.vue';
import AppExternalButton from '@/components/buttons/AppExternalButton.vue';
import AppCheckbox from '@/components/common/controls/AppCheckbox.vue';
import AppSelectableCard from '@/components/common/cards/AppSelectableCard.vue';
import AppSwitch from '@/components/buttons/AppSwitch.vue';
import AppExpansionPanels from '@/components/buttons/AppExpansionPanels.vue';
import AppGuideAvatar from '@/components/guide/AppGuideAvatar.vue';
import AppPageGuideLocator from '@/components/common/app-page/AppPageGuideLocator.vue';
import AppPageGuideMessageContainer from '@/components/common/app-page/AppPageGuideMessageContainer.vue';
import AppPageTabs from '@/components/common/app-page/AppPageTabs.vue';
import AppTooltip from '@/components/header/AppTooltip.vue';
import AppGlobalLoader from '@/components/common/AppGlobalLoader.vue';
import AppPageTabsFooter from '@/components/common/app-page/AppPageTabsFooter.vue';
import AppPageTabsFooterActions from '@/components/common/app-page/AppPageTabsFooterActions.vue';

/**
 * Make components available in every vue file without needing to import them explicitly.
 */
export function registerAppComponents(Vue: Application) {
    Vue.component('AppButton', AppButton);
    Vue.component('AppExternalButton', AppExternalButton);
    Vue.component('AppPlusButton', AppPlusButton);
    Vue.component('AppButtonIcon', AppButtonIcon);
    Vue.component('AppTooltip', AppTooltip)

    Vue.component('AppCheckbox', AppCheckbox);
    Vue.component('AppSwitch', AppSwitch);
    Vue.component('AppExpansionPanels', AppExpansionPanels);

    // cards
    Vue.component('AppSelectableCard', AppSelectableCard);

    Vue.component('AppGuideAvatar', AppGuideAvatar);
    Vue.component('AppGuideChatDialog', GuideChatDialog);
    Vue.component('AppGuideChatActivator', GuideChatActivator);
    Vue.component('AppGuideModal', AppGuideModal);
    Vue.component('AppTutorial', AppTutorial);
    Vue.component('AppGlobalLoader', AppGlobalLoader);

    Vue.component('AppGuideInline', AppGuideInline);
    Vue.component('AppNotification', AppNotification);

    Vue.component('AppActivityLayout', AppActivityLayout);
    Vue.component('AppSortingControls', AppSortingControls);
    Vue.component('AppPage', AppPage);
    Vue.component('AppPageTabs', AppPageTabs);
    Vue.component('AppPageTabsFooter', AppPageTabsFooter);
    Vue.component('AppPageTabsFooterActions', AppPageTabsFooterActions);

    Vue.component('AppPageHeader', AppPageHeader);
    Vue.component('AppPageHeaderProgress', AppPageHeaderProgress);
    Vue.component('AppPageContent', AppActivityLayout);
    Vue.component('AppPageContentIntro', AppPageContentIntro);
    Vue.component('AppPageFooter', AppPageFooter);
    Vue.component('AppPageGuideLocator', AppPageGuideLocator);
    Vue.component('AppPageGuideMessageContainer', AppPageGuideMessageContainer);
    Vue.component('AppLabel', AppLabel);

    // Register FontAwesomeIcon as a global component
    Vue.component('AppFaIcon', FontAwesomeIcon);
}
