<template>
    <v-container class="pa-0">
        <v-card variant="plain" class="text-custom-purple-deep my-2 pa-0">
            <v-card-text class="text-paragraph-md px-0 py-2">
                <p>How about you focus on:</p>
            </v-card-text>
        </v-card>
        <v-card
            v-for="(item, i) in items"
            :key="i"
            color="custom-neutral-grey-100"
            min-height="130"
            class="rounded-0 box-shadow-grey my-4 w-100"
        >
            <v-card-item class="mt-4 mb-2">
                <v-expansion-panels
                    v-if="item?.description"
                    class="w-100 rounded-0 border-0 suggestion-guide-action-item"
                >
                    <v-expansion-panel
                        elevation="0"
                        bg-color="custom-neutral-grey-100"
                        class="w-100 rounded-0 border-0 pa-0 ma-0"
                    >
                        <v-expansion-panel-title
                            color="custom-neutral-grey-100"
                            class="v-row w-100 pa-0 ma-0 text-heading-h5 font-weight-regular text-use-heading-font"
                        >
                            {{ item?.title }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="px-0">
                            {{ item?.description }}
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
                <span v-else class="text-heading-h5 font-weight-regular text-use-heading-font mt-8">
                    {{ item?.title }}
                </span>
            </v-card-item>
            <v-card-actions class="px-8 py-4 current-challenge-suggestion-guide-action">
                <v-spacer />
                <app-plus-button @click="() => onAddAction(item)">Add to my list</app-plus-button>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script setup lang="ts">
    import {
        type GuideMessageEvent,
        type GuideMessage,
        type GuideSuggestionMessageContent,
        isSuggestionMessage,
    } from '@/store/guide/types';
    import { useCurrentChallengeStore } from '@/store/current-challenges/store';
    import { useGuideStore } from '@/store/guide/store';
    import { useDisplay } from 'vuetify';
    import { computed, type ComputedRef } from 'vue';

    const store = useCurrentChallengeStore();
    const guideStore = useGuideStore();

    const { name } = useDisplay();

    const props = defineProps<{
        message: GuideMessage;
    }>();

    const items: ComputedRef<GuideSuggestionMessageContent[]> = computed(
        (): GuideSuggestionMessageContent[] => {
            if (isSuggestionMessage(props.message)) {
                const message = (props?.message?.ctx as GuideMessageEvent)?.value;
                const value = message?.content;
                return Array.isArray(value) ? value : [value as GuideSuggestionMessageContent];
            }

            return [];
        },
    );

    const onAddAction = (item: GuideSuggestionMessageContent) => {
        store.onAddChallenge(item.title);
        guideStore.hideDialog();
    };
</script>

<style scoped lang="scss">
    :deep(.v-expansion-panel-title__overlay) {
        background-color: unset;
    }

    :deep(.v-expansion-panel-title) {
        .v-icon {
            font-size: 24px;
        }
    }

    :deep(.v-expansion-panel-text__wrapper) {
        padding-left: 0px;
        padding-right: 0px;
    }

    :deep(
            .v-expansion-panel--active
                > .v-expansion-panel-title:not(.v-expansion-panel-title--static)
        ) {
        min-height: unset;
    }

    .suggestion-guide-action-item {
        margin: 0px;
    }

    .current-challenge-suggestion-guide-action {
        background-color: rgb(var(--v-theme-custom-neutral-grey-200));
    }
</style>
