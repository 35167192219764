<template>
    <!-- ml-n4 compensates in mobile the padding on the <header />, so it starts next to the left    -->
    <v-list density="compact" nav>
        <AppHeaderListItem
            class="mb-4 pa-0"
            :to="homeRoute"
            :active="urlStore.isHomePath"
            :disabled="!userStore.hasProfileBeenSet"
            icon="home"
            tooltip="Home"
        />
        <AppHeaderListItem
            class="mb-4 pa-0"
            :to="storyRoute"
            :active="urlStore.isStoryPath"
            :disabled="!userStore.hasProfileBeenSet"
            icon="book"
            tooltip="Story"
        />
        <AppHeaderListItem
            class="pa-0"
            :to="challengesHomeRoute"
            :active="urlStore.isChallengePath"
            :disabled="!userStore.hasProfileBeenSet"
            icon="road"
            tooltip="Solve"
        />

        <AppHeaderListItem v-if="showGP" class="pa-0" icon="tree" tooltip="Growth Path" />
    </v-list>
</template>

<script setup lang="ts">
    import { useUsersStore } from '@/store/user/store';
    import { challengesHomeRoute, homeRoute, storyRoute } from '@/router/routes';
    import AppHeaderListItem from '@/components/header/AppHeaderListItem.vue';
    import { useURLStore } from '@/store/navigation/useURLStore';

    const userStore = useUsersStore();

    const urlStore = useURLStore();

    const showGP = false;
</script>

<style scoped lang="scss">
    .btn-navigation {
        opacity: 0.2;
    }

    :deep(.v-list-item-title) {
        font-size: 18px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.045px;
    }
</style>
