import { defineStore } from 'pinia';
import type { ActivityStoreState } from '@/store/activity/types';
import {
    addEntryActivity,
    addExitActivity,
    fetchUserActivityCompleted,
} from '@/services/activity/service';
import {
    makeDataState,
    makeDataStateGetters,
    setInError,
    setInIdle,
    setInLoading,
    setInSyncing,
} from '@/store/common/dataState';
import { type UserActivity, UserActivityArea, UserActivityEvent } from '@/api/types/userActivity';
import { last } from 'lodash';
import { useUsersStore } from '@/store/user/store';
import { verify } from '@/store/verify';
import { format } from 'date-fns';

export type RefreshActivityResultUpdated = { changed: true; activity: UserActivity };
export type RefreshActivityResultNotUpdated = { changed: false; activity: null };
export type RefreshActivityResult = RefreshActivityResultUpdated | RefreshActivityResultNotUpdated;

export const useActivityStore = defineStore({
    id: 'activity-store',
    state: (): ActivityStoreState => ({
        activities: [],
        ...makeDataState(),
    }),
    getters: {
        ...makeDataStateGetters(),
        completed(state): UserActivity[] {
            const completed = state.activities.filter((a) => {
                return (
                    a.event === UserActivityEvent.Completed &&
                    [
                        UserActivityArea.CurrentChallenges,
                        UserActivityArea.FutureAspiration,
                        UserActivityArea.LearnedExperiences,
                        UserActivityArea.PersonalValues,
                        UserActivityArea.PersonalityType,
                        UserActivityArea.PersonalStrengths,
                        UserActivityArea.Skills,
                        UserActivityArea.UniqueValueProposition,
                    ].includes(a.area)
                );
            });

            return completed;
        },
        lastCompleted(state): UserActivity | null {
            return last(this.completed) ?? null;
        },
        lastCompletedDate(state): string | null {
            const createdAt = this.lastCompleted?.created_at;
            return createdAt ? format(new Date(createdAt), 'dd/MM/yy') : null;
        },
        lastCompletedActivityLabel(state): string | null {
            const activity = this.lastCompleted;

            switch (activity?.area) {
                case UserActivityArea.CurrentChallenges:
                    return 'Current challenges';
                case UserActivityArea.FutureAspiration:
                    return 'Future aspiration';
                case UserActivityArea.LearnedExperiences:
                    return 'Memorable Experiences';
                case UserActivityArea.PersonalStrengths:
                    return 'Personal strengths';
                case UserActivityArea.PersonalValues:
                    return 'Personal values';
                case UserActivityArea.PersonalityType:
                    return 'Personality type';
                case UserActivityArea.Skills:
                    return 'Skills';
                case UserActivityArea.UniqueValueProposition:
                    return 'Unique Value Proposition';
                default:
                    return null;
            }
        },
    },
    actions: {
        async load(): Promise<void> {
            const context = await useUsersStore().makeContext();

            setInLoading(this);

            try {
                this.activities = await fetchUserActivityCompleted(
                    context.user.id,
                    context.accessToken,
                );

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }
        },
        async refresh(): Promise<RefreshActivityResult> {
            const context = await useUsersStore().makeContext();
            setInSyncing(this);

            const beforeRefreshAmountOfCompleted = this.activities.length;

            try {
                this.activities = await fetchUserActivityCompleted(
                    context.user.id,
                    context.accessToken,
                );

                setInIdle(this);
            } catch (error) {
                setInError(this, error);
            }

            const currentAmountOfCompleted = this.activities.length;

            if (
                currentAmountOfCompleted > 0 &&
                beforeRefreshAmountOfCompleted !== currentAmountOfCompleted
            ) {
                return {
                    changed: true,
                    activity: verify(this.lastCompleted, 'last activity cannot be null'),
                };
            } else {
                return { changed: false, activity: null };
            }
        },
        async addEntryActivity(area: UserActivityArea): Promise<void> {
            const { user, accessToken } = await useUsersStore().makeContext();
            await addEntryActivity(user.id, area, accessToken);
        },
        async addExitActivity(area: UserActivityArea): Promise<void> {
            const { user, accessToken } = await useUsersStore().makeContext();
            await addExitActivity(user.id, area, accessToken);
        },
    },
});
