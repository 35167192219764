<template>
    <app-page-content>
        <template #intro>
            <app-page-content-intro title="Choose activities that will help with your challenge">
                We recommend you start with three activities. If you don't solve the challenge you
                can always come back and try some other activities later.

                <br />
                <div class="my-2">
                    <app-label class="mr-3" :type="PlanActionItemType.ExperienceActionType" />
                    <span
                        >Things you can do on your own. These are most suited to people who prefer
                        ‘self-learning’ or reflective activities.
                    </span>
                </div>
                <div class="my-2">
                    <app-label class="mr-3" :type="PlanActionItemType.ConnectActionType" />
                    <span
                        >Work with or learn from others. These are most suited to people who enjoy
                        ‘learning by doing’ or ‘social learning’.</span
                    >
                </div>
                <div class="my-2">
                    <app-label class="mr-3" :type="PlanActionItemType.NewSkillActionType" />
                    <span
                        >Up-skill or build a new capability. These are more formal activities or
                        methods for learning a skill and that may require higher focus or
                        application.
                    </span>
                </div>
            </app-page-content-intro>
        </template>

        <div v-if="!isLoadingMoreActivities">
            <SingleChallengeNewActionListItem
                v-for="(action, index) in activities"
                :key="index"
                :item="action"
                class="mb-2 w-100"
                @read-more-click="onReadMore(action)"
            />

            <div v-if="store._timeoutError">
                <div class="mt-4">
                    Sorry! Looks like your Pathway didn't load this time. Refresh and let's
                    <span class="px-0 cursor-pointer text-secondary-blue" @click="tryAgain">
                        try again.</span
                    >
                </div>
            </div>

            <SingleChallengeActionForm v-if="store.current" />
            <SingleChallengeActionButtons
                v-else
                @create-own-activity="store.addActivity"
                @fetch-new-activities="loadMoreActivities"
            />
        </div>
        <app-global-loader v-else />
    </app-page-content>

    <v-dialog close-on-back max-width="640" v-model="showReadModal">
        <SingleChallengeNewActionListItemReadMore
            v-if="current"
            :item="current"
            class="mb-2 w-100 fill-height"
            @close="showReadModal = false"
        />
    </v-dialog>

    <SingleChallengeNewGuide />
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import {
        type SelectableChallengeAction,
        useSingleChallengePathStore,
    } from '@/store/challenge-path/singleChallengeStore';
    import { verify } from '@/store/verify';
    import { PlanActionItemType } from '@/store/plan/common/types';
    import SingleChallengeNewActionListItem from '@/components/pathway/single-challenge/new/SingleChallengeNewActionListItem.vue';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import SingleChallengeNewActionListItemReadMore from '@/components/pathway/single-challenge/new/SingleChallengeNewActionListItemReadMore.vue';
    import SingleChallengeNewGuide from '@/components/pathway/single-challenge/new/SingleChallengeNewGuide.vue';
    import SingleChallengeActionForm from '@/components/pathway/single-challenge/SingleChallengeActionForm.vue';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import SingleChallengeActionButtons from '@/components/pathway/single-challenge/SingleChallengeActionButtons.vue';
    import Loader from '@/components/common/Loader.vue';
    import { useLoadingStore } from '@/store/loadingStore';
    import { isInProgress, isNew } from '@/store/challenge-path/util';

    const current = ref<SelectableChallengeAction | null>(null);
    const showReadModal = ref(false);

    const store = useSingleChallengePathStore();
    const navigationStore = useNavigationStore();

    const loadingStore = useLoadingStore();

    const challenge = navigationStore.ctx.item;
    const challengeId = Number(verify(challenge.id, 'No challenge id'));

    console.log(`Loading single challenge ${challengeId}`);

    if (isNew(challenge)) {
        await store.loadNew(challengeId);
    } else {
        await store.loadEdit(challengeId);
    }

    const isLoadingMoreActivities = ref(false);
    const loadMoreActivities = async () => {
        try {
            isLoadingMoreActivities.value = true;
            await store.loadMore(challengeId);
        } finally {
            isLoadingMoreActivities.value = false;
        }
    };

    const onReadMore = (item: SelectableChallengeAction) => {
        showReadModal.value = true;
        current.value = item;
    };

    const tryAgain = async () => {
        try {
            isLoadingMoreActivities.value = true;
            if (isNew(challenge)) {
                await store.loadNew(challengeId);
            } else {
                await store.loadEdit(challengeId);
            }
        } finally {
            isLoadingMoreActivities.value = false;
        }
    };

    const activities = computed(() => {
        if (isNew(challenge)) {
            return store.selectableActions;
        } else if (isInProgress(challenge)) {
            return store.inProgressSelectableActions;
        } else {
            return [];
        }
    });
</script>
