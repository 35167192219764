/**
 * A closure to create a function that will log the user out in an orchestrated way.
 *
 * Note: The logout and clean up leads at the moment with two entities:
 * - inline guide store: a simple pinia store, which in theory could be used from anywhere
 * - a logout from auth0 which is a simple function that the closure holds on to execute later on.
 *
 * Note: We do not do useAuth0() in a commmon pinia store as useAuth0() only works inside vue components,
 * therefore we hold to this value in a simple closure.
 */
export const makeLogoutHandle =
    (
        inlineGuideStore: {
            clearStorage: () => void;
        },
        logout: (logoutParams: any) => Promise<void>,
    ) =>
    async () => {
        // Clear the inline guide store local storage.
        // This is not perfect, but it's a quick fix to clear the inline guide store.
        // Ideally, we should clear the storage when the user logs in, but could not detect that change with auth0 sdk.
        inlineGuideStore.clearStorage();

        await logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };
