<template>
    <v-btn class="text-custom-purple-deep bg-white" variant="outlined" @click="handleLogout">
        Log Out
    </v-btn>
</template>

<script setup lang="ts">
    import { useAuth0 } from '@/auth/auth';
    import { useInlineGuideStore } from '@/store/inline-guide/store';
    import { makeLogoutHandle } from '@/components/utils';

    const { logout } = useAuth0();
    const store = useInlineGuideStore();
    const handleLogout = async () => {
        const _logout = makeLogoutHandle(store, logout);
        await _logout();
    }
</script>
