<template>
    <v-dialog v-model="show" max-width="640">
        <v-card class="pa-6">
            <v-card-title class="px-0 text-heading-h3 text-wrap"
                >Unarchive <span class="font-weight-700">{{ item.description }}</span> challenge
            </v-card-title>
            <v-card-text class="px-0 text-paragraph-md">
                Are you sure you want to unarchive this challenge?.
            </v-card-text>
            <v-card-actions class="px-0">
                <app-button @click="onCancel">Cancel</app-button>
                <v-spacer />
                <app-button color="primary" variant="flat" @click="onUnarchiveClick"
                    >Yes, unarchive
                </app-button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useRouter } from 'vue-router';
    import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';

    const props = defineProps<{
        modelValue: boolean;
        item: CanvasCurrentChallenge;
    }>();

    const emit = defineEmits(['update:modelValue']);
    const show = ref(false);
    const router = useRouter();

    const onCancel = () => {
        show.value = false;
    };

    // Watch external value and sync local state
    watch(
        () => props.modelValue,
        (newVal) => {
            show.value = newVal;
        },
        { immediate: true },
    );

    // Update parent when local state changes
    watch(
        () => show.value,
        (newVal) => {
            emit('update:modelValue', newVal);
        },
    );

    const store = useChallengePathStore();
    const isActivating = ref(false);

    const navigationStore = useNavigationStore();

    const onUnarchiveClick = async () => {
        try {
            isActivating.value = true;
            await store.activateChallenge(props.item);

            // reload store to retrieve latest challenge status
            await store.load();

            await navigationStore.setTabByKey('progress', { item: props.item });
        } finally {
            isActivating.value = true;
            show.value = false;
        }
    };
</script>
