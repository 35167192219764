<template>
    <PlanActionListItemView
        v-if="
            props.item.state === PlanActionItemState.New ||
            props.item.state === PlanActionItemState.Deleting ||
            props.item.state === PlanActionItemState.Saved ||
            props.item.state === PlanActionItemState.Error
        "
        :item="props.item"
        :index="props.index"
    />
    <PlanActionListItemEdit
        v-else-if="
            props.item.state === PlanActionItemState.Edit ||
            props.item.state === PlanActionItemState.Updating ||
            props.item.state === PlanActionItemState.Creating
        "
        :item="props.item"
        :index="props.index"
    />
</template>

<script setup lang="ts">
    import PlanActionListItemView from '@/components/plan/common/PlanActionListItemView.vue';
    import PlanActionListItemEdit from '@/components/plan/common/PlanActionListItemEdit.vue';
    import { type EditablePlanAction, PlanActionItemState } from '@/store/plan/common/types';

    const props = defineProps<{
        item: EditablePlanAction;
        index: number;
    }>();
</script>

<style scoped lang="scss">
    .plan-action-list-item:not(:last-child) {
        border-bottom: 1px solid rgb(var(--v-theme-custom-neutral-grey-700));
    }
</style>
