<template>
    <app-button
        v-for="(item, index) in switchValues"
        :key="index"
        height="38"
        variant="flat"
        v-model="item.value"
        :color="item.color"
        :loading="loading"
        :disabled="item.disabled"
        :size="size"
        @click="disabled ? () => {} : emitChange(item)"
        :class="item.cssClass"
        override-css="px-5"
    >
        <span class="text-paragraph-md font-weight-600">{{ item.label }}</span>
    </app-button>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import type { SwitchOption } from '@/components/canvas/skills/util';

    const emit = defineEmits<{
        (e: 'change', payload: SwitchOption): void;
    }>();

    const props = withDefaults(
        defineProps<{
            items: SwitchOption[];
            color?: string;
            disabled?: boolean;
            loading?: boolean;
            size?: 'x-small' | 'small' | 'default' | 'large' | 'x-large';
            weight?: 'light' | 'regular' | 'medium' | 'bold' | 'black' | 'thin' | '600' | '700';
            borderColor?:
                | 'neutral-black'
                | 'secondary-blue'
                | 'secondary-pink'
                | 'secondary-purple';
            overrideCss?: string;
            selectedColor?: string;
            unselectedColor?: string;
        }>(),
        {
            selectedColor: 'primary',
            unselectedColor: 'custom-neutral-grey-200',
            color: 'primary',
            size: 'default',
            overrideCss: '',
        },
    );

    function emitChange(item: SwitchOption) {
        emit('change', item);
    }

    const switchValues = computed(() => {
        return props.items.map((item) => {
            return {
                ...item,
                disabled: props.disabled,
                color: item.selected ? (item.color ?? props.selectedColor) : props.unselectedColor,
                cssClass: {
                    'selected': item.selected,
                    'not-selected': !item.selected,
                    'button-disabled': props.disabled,
                },
            };
        });
    });
</script>

<style scoped lang="scss">
    :deep(button) {
        padding: 6px 20px;

    }
</style>
