<template>
    <div id="guide-teleport-container-box" :class="responsiveClass" />
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { responsiveClass } = useIsDesktop();
</script>

<style lang="scss" scoped>
    #guide-teleport-container-box {
        position: fixed;
        top: 50px;
        display: flex;
        width: 560px;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        z-index: 9999;

        &.desktop {
            right: 0;
        }

        &.mobile {
            left: 0;
        }

        //padding: 0;
        //position: sticky;
        //margin-left: auto; /* Ensure it aligns to the right */
        //text-align: right !important;
        //margin-right: 0px !important;

        // one less than the z-index of the guide
        //z-index: 2399;

        //&.desktop {
        //    width: 500px;
        //}

        &.mobile {
            width: 100%;
        }
    }
</style>
