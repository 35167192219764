<template>
    <v-snackbar
        v-model="model.show"
        variant="elevated"
        location="bottom"
        :color="color"
        :content-class="`submit-notifications ${name}`"
        z-index="99999"
        :min-height="isDesktop ? 50 : 50"
    >
        <v-icon
            color="green"
            v-if="model.type === NotificationType.Success"
            size="x-large"
            class="mx-2 mb-1"
        >
            mdi-checkbox-marked-circle
        </v-icon>
        <span class="text-paragraph-md">
            {{ message }}
        </span>
        <template v-slot:actions>
            <v-btn icon="mdi-close" variant="text" @click="model.show = false" />
        </template>
    </v-snackbar>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useDisplay } from 'vuetify';
    import { NotificationType } from '@/components/canvas/notifications/notifications';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { name } = useDisplay();
    type ModelType = {
        show: boolean;
        type: NotificationType;
        message: null | string;
    };

    const props = defineProps<{
        model: ModelType;
    }>();

    const message = computed(() => {
        if (props.model.message) {
            return props.model.message;
        }

        switch (props.model.type) {
            case NotificationType.Error:
                return 'An error has occurred. Please try again';
            case NotificationType.Success:
                return 'Saved successfully';
            case NotificationType.FirstTimeCompleted:
                return 'Well done completing this activity!';
        }
    });

    const { isDesktop } = useIsDesktop();

    const color = computed(() => {
        const type = props.model.type;
        switch (type) {
            case NotificationType.Error:
                return 'custom-red';
            case NotificationType.Success:
                return 'custom-neutral-grey-900';
            case NotificationType.FirstTimeCompleted:
                return 'custom-neutral-grey-900';
        }
    });

    const timeout = computed(() => {
        switch (props.model.type) {
            case NotificationType.Error:
                // Errors are shown indefinitely until closed, or removed by the application
                // e.g.: a new success notification took over
                return -1;
            case NotificationType.Success:
                return 4000;
            case NotificationType.FirstTimeCompleted:
                return 10000;
        }
    });
</script>

<style lang="scss">
    .submit-notifications {
        bottom: 100px !important;
    }

    .submit-notifications.xs,
    .submit-notifications.sm {
        bottom: 150px !important;
    }
</style>
