<template>
    <v-card
        color="white"
        min-height="80"
        elevation="0"
        class="rounded-0 pa-4 ma-0 plan-action-list-item"
    >
        <v-card-item class="pa-0">
            <template v-slot:prepend> {{ props.index + 1 }}.</template>
            <span v-if="props.item.title" class="mx-0 text-paragraph-md">
                {{ props.item.title }}
            </span>
            <span
                v-else
                class="mx-0 text-paragraph-md font-italic text-custom-neutral-grey-700 cursor-pointer w-100"
                @click="emit('edit-click', props.item)"
            >
                Add title
            </span>
            <template v-slot:append>
                <v-icon @click="emit('edit-click', props.item)" class="align-start mr-4"
                    >mdi-pencil
                </v-icon>
                <v-icon @click="emit('remove-click', props.item)">mdi-close</v-icon>
            </template>
        </v-card-item>
        <v-card-text class="ma-2 text-paragraph-md">
            <v-icon color="custom-purple-mid" size="x-large">mdi-calendar-blank-outline</v-icon>
            {{ displayDueDate }}
        </v-card-text>
        <v-card-actions v-if="props.item.state === PlanActionItemState.Error">
            <v-alert color="error" :closable="false">
                There was an error
                <span v-if="props.item.prevState === PlanActionItemState.Creating">creating</span>
                <span v-else-if="props.item.prevState === PlanActionItemState.Updating"
                    >updating</span
                >
                <span v-else-if="props.item.prevState === PlanActionItemState.Deleting"
                    >removing</span
                >
                <span v-else>saving</span>
                this action
            </v-alert>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { format } from 'date-fns';
    import { type EditablePlanAction, PlanActionItemState } from '@/store/plan/common/types';

    const props = defineProps<{
        item: EditablePlanAction;
        index: number;
    }>();

    const emit = defineEmits(['edit-click', 'remove-click']);

    const displayDueDate = computed(() => {
        const date = props.item.due_date;
        return date ? format(date, 'dd MMMM yyyy') : '';
    });
</script>
