<template>
    <v-row class="pa-0 ga-16" no-gutters>
        <v-col :cols="isDesktop ? true : 12">
            <v-img :src="solveHeadingImage" />
        </v-col>
        <v-col :cols="isDesktop ? true : 12">
            <v-container
                class="pa-0 w-100 fill-height flex-column"
                :class="isDesktop ? 'justify-center align-center' : ''"
            >
                <div class="text-paragraph-lg w-100">
                    Everyone everyday at work faces challenges. Many that hold us back from
                    achieving our goals. We are here to help and provide self-directed activities
                    for you to work on and solve challenges in your own way.
                </div>
                <div
                    class="text-paragraph-sm font-weight-600 mt-4 w-100 cursor-pointer"
                    @click="emit('click')"
                >
                    About Solve
                    <app-fa-icon icon="fa-arrow-down" class="ml-4" />
                </div>
            </v-container>
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import solveHeadingImage from '@/assets/images/challenges/heading.svg';

    const emit = defineEmits(['click']);
    const { isDesktop } = useIsDesktop();
</script>
