<template>
    <v-card variant="plain" width="340" class="pa-0 bg-custom-neutral-grey-100 opacity-1">
        You also shared that you wanted to achieve this goal by
        <b>{{ when }}</b
        >, while living in
        <b>{{ where }}</b>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useCareerPlanStore } from '@/store/plan/career/store';

    const store = useCareerPlanStore();

    const when = computed(() => {
        const timeframe = store.aspiration?.timeframe;
        return timeframe ? timeframe : 'not specified';
    });

    const where = computed(() => {
        const location = store.aspiration?.location;
        return location ? location : 'not specified';
    });
</script>
