<template>
    <v-container :class="responsiveClass" class="welcome-card pa-0">
        <div class="w-100 pa-0 welcome-card-top-image mb-8" style="position: relative" fluid>
            <v-row justify="space-around" class="bg-custom-neutral-grey-100" no-gutters>
                <v-img
                    :src="isDesktop ? welcomeBannerDesktop : welcomeBannerMobile"
                    color="white"
                />
            </v-row>
        </div>

        <v-container class="w-100 pa-0">
            <HomeProfileProgress />
        </v-container>

        <v-container class="w-100 pa-0">
            <v-row class="ga-5" no-gutters>
                <v-col class="d-flex" :cols="true" v-for="(introCard, index) in introCards">
                    <HomeIntroCard
                        :key="index"
                        :type="introCard.type"
                        :image="introCard.image"
                        :title="introCard.title"
                        :description="introCard.description"
                        :disabled="introCard.disabled"
                        @click="() => onLearnMore(introCard)"
                    />
                </v-col>
            </v-row>
        </v-container>

        <div ref="storyRef" v-if="isDesktop">
            <HomeStorySection />
        </div>

        <div ref="challengeRef" v-if="isDesktop">
            <HomeSolveSection />
        </div>

        <div ref="growthRef" v-if="isDesktop">
            <HomeGrowthSection />
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import welcomeBannerDesktop from '@/assets/images/home/welcome-back-desktop.svg';
    import welcomeBannerMobile from '@/assets/images/home/welcome-back-mobile.svg';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import HomeStorySection from '@/components/home/HomeStorySection.vue';
    import HomeSolveSection from '@/components/home/HomeChallengesSection.vue';
    import HomeGrowthSection from '@/components/home/HomeGrowthSection.vue';
    import HomeIntroCard from '@/components/home/HomeIntroCard.vue';

    import storyImage from '@/assets/images/story/my-personal-skills.svg';
    import challengesImage from '@/assets/images/story/challenge-pathways.svg';
    import growthImage from '@/assets/images/story/personality.svg';
    import { useRouter } from 'vue-router';
    import { challengesHomeRoute, storyRoute } from '@/router/routes';
    import { type Ref, ref } from 'vue';
    import type { IntroCard } from '@/components/home/types';
    import HomeProfileProgress from '@/components/home/HomeProfileProgress.vue';

    const storyRef = ref<HTMLElement | null>(null);
    const challengeRef = ref<HTMLElement | null>(null);
    const growthRef = ref<HTMLElement | null>(null);

    function scrollToAbout(ref: Ref<HTMLElement | null>) {
        ref.value?.scrollIntoView({ behavior: 'smooth' });
    }

    const introCards: IntroCard[] = [
        {
            type: 'story',
            image: storyImage,
            title: 'Story',
            description:
                'Start here, to keep a record of your career history and professional achievements. We will also help you discover your Superpower. Keep Story updated and share with others.',
            to: storyRoute,
            ref: storyRef,
        },
        {
            type: 'challenges',
            image: challengesImage,
            title: 'Solve',
            description:
                'Next, identify and solve challenges in your work that might be holding you back. We personalise your learning and growth to accelerate your progress. Share your workplan with your manager.',
            to: challengesHomeRoute,
            ref: challengeRef,
        },
        {
            type: 'growth',
            image: growthImage,
            title: 'Growth',
            description:
                'Lastly, craft a career pathway for your future. We will show you options and jobs that suit your profile, and help you forge a plan to transition. We rely on your Story being updated so keep active!',
            to: challengesHomeRoute,
            ref: growthRef,
            disabled: true,
        },
    ];

    const emit = defineEmits(['next-click']);
    const { responsiveClass, isDesktop } = useIsDesktop();

    const router = useRouter();

    const onLearnMore = async (introCard: IntroCard) => {
        isDesktop.value ? await onLearnMoreDesktop(introCard) : await onLearnMoreMobile(introCard);
    };
    const onLearnMoreDesktop = async (introCard: IntroCard) => {
        scrollToAbout(introCard.ref);
    };

    const onLearnMoreMobile = async (introCard: IntroCard) => {
        if (introCard.disabled) return;

        await router.push(introCard.to);
    };
</script>

<style scoped lang="scss">
    .your-safe-space-card {
        background: rgb(var(--v-theme-primary-light));
    }

    .welcome-card > div:last-child {
        margin-bottom: 64px !important;
    }

    .welcome-card {
        display: flex;
        flex-direction: column;
    }

    .welcome-card.desktop {
        gap: 64px;

        padding-bottom: 64px;
        padding-left: 80px;
        padding-right: 80px;
        align-items: center;

        .your-safe-space-card {
            border-radius: 32px;
            display: flex;
            padding: 48px 64px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 40px;
            align-self: stretch;
        }
    }

    .welcome-card.mobile {
        width: 100%;
        gap: 20px;

        .welcome-card-top-image {
            width: 100%;
            height: 100%;
        }

        .your-safe-space-card {
            width: 100%;
            padding-top: 80px !important;
        }
    }

    .why-card,
    .the-process-card {
        background: white;
        border: 2px rgb(var(--v-theme-story-outline)) solid;
    }

    .meet-assistant-card {
        background: rgb(var(--v-theme-secondary-purple-light));
    }

    .why-card,
    .the-process-card,
    .meet-assistant-card {
        flex-direction: column;
        align-self: stretch;
        justify-content: flex-start;

        display: inline-flex;

        &.desktop {
            gap: 48px;
            display: flex;
            padding: 48px 64px;
            align-items: center;

            border-radius: 32px;
        }

        &.mobile {
            gap: 32px;
            display: flex;
            padding: 32px 16px;
            align-items: flex-start;

            border-radius: 8px;
        }
    }

    .meet-assistant-intro {
        &.desktop,
        &.mobile.md {
            width: 772px;
            gap: 32px;
            display: inline-flex;

            .meet-assistant-intro-avatar-wrapper {
                background: linear-gradient(180deg, #836eac 0%, #be96ba 100%);
                border-radius: 1500px;
                overflow: hidden;
                border: 3px #1638a5 solid;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }

        &.mobile.sm,
        &.mobile.xs {
            gap: 32px;
            display: block;

            margin-bottom: 48px;

            .meet-assistant-intro-avatar-wrapper {
                margin-bottom: 20px;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
    }

    .dot-divider {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }
</style>
