<template>
    <app-tutorial section-name="Challenges" action-text="Start">
        <template #title="{ step }">
            <span v-if="step === 1">What do we mean by challenges...</span>
        </template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    If you could eliminate problems or blockers from your working day, what would be
                    your top priority?
                </p>
                <p>
                    Think about times when you come under pressure, or feel under-prepared in your
                    working day. What are the common themes or problems you are experiencing
                    regularly?
                </p>
            </div>
        </template>
    </app-tutorial>
</template>
