<template>
    <v-card
        variant="outlined"
        class="pa-4 py-0 d-flex flex-column w-100 border-dashed"
        :min-height="60"
    >
        <template v-slot:prepend>
            <v-icon size="20" color="secondary" icon="mdi-file-document-outline"></v-icon>
        </template>
        <template v-slot:title>
            <span class="text-subtitle-1 text-custom-neutral-grey-600 w-75 font-weight-light text-wrap">{{
                file.name
            }}</span>
        </template>
        <template v-slot:append>
            <div class="text-custom-neutral-grey-600 d-flex text-caption text-wrap text-right">
                Uploaded <br />
                {{ lastTimeUploaded }} <br />
            </div>
        </template>
    </v-card>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import formatDistanceToNow from 'date-fns/formatDistanceToNow';

    const props = defineProps<{
        file: File;
    }>();

    const lastTimeUploaded = computed(() => {
        if (props.file) {
            const when = new Date(props.file.lastModified);

            return formatDistanceToNow(when, { addSuffix: true });
        } else {
            return null;
        }
    });
</script>
