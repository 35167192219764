<template>
    <v-container class="pa-0 d-print-none" fluid>
        <StoryHeading @click="scrollToAbout" />
    </v-container>

    <v-container class="pa-0 align-baseline" fluid>
        <StoryGuide />

        <ContextualInlineGuide :section="InlineGuideSection.Story" />

        <StoryHeadingPrintOnly class="d-print-only" />

        <v-row
            v-if="showUniqueValueProposition"
            class="pa-0 w-100 print-story-section-layout-container print-do-not-break"
            no-gutters
            :class="isDesktop ? 'mb-8' : 'mb-5'"
        >
            <StoryUniqueValueProposition />
        </v-row>

        <v-row
            class="pa-0 w-100 print-story-section-layout-container print-do-not-break"
            no-gutters
            :class="isDesktop ? 'mb-8' : 'mb-5'"
        >
            <!-- The what I offer section is always expanded -->
            <StorySection :section="whatIOffer" />
        </v-row>

        <v-row
            class="pa-0 w-100 print-story-section-layout-container print-do-not-break"
            no-gutters
            :class="isDesktop ? 'mb-8' : 'mb-5'"
        >
            <StorySectionNotStarted
                v-if="
                    whoIAm.status === StorySectionState.NotStarted &&
                    !uiStoryStore.whoIAmSectionExpanded
                "
                @click="uiStoryStore.expandWhoIAm"
                :section="whoIAm"
            />
            <StorySection v-else :section="whoIAm" />
        </v-row>

        <v-row
            class="pa-0 w-100 print-story-section-layout-container print-do-not-break"
            no-gutters
        >
            <StorySectionNotStarted
                v-if="
                    whatIAmWorkingOn.status === StorySectionState.NotStarted &&
                    !uiStoryStore.whatIAmWorkingOnSectionExpanded
                "
                @click="uiStoryStore.expandWhatIAmWorkingOn"
                :section="whatIAmWorkingOn"
            />
            <StorySection v-else :section="whatIAmWorkingOn" />
        </v-row>
    </v-container>

    <!--
        Use a simple div instead of attaching the ref to the v-row,
        so scrolling works on the HTMLElement. Otherwise $el has to be used
     -->
    <div ref="about">
        <v-row class="pa-0 w-100 d-print-none" no-gutters>
            <StoryHomeAbout class="mb-16" />
        </v-row>
    </div>

    <v-row class="pa-0 w-100 d-print-none" v-if="false && showChallengePathCard" no-gutters>
        <StoryChallengePathCard />
    </v-row>

    <div class="print-copyright d-print-only">
        This story was created by Actvo. ©Career Concierge Limited
    </div>
</template>

<script setup lang="ts">
    import StorySection from '@/components/story/section/StorySection.vue';
    import { useStoryStore } from '@/store/story/store';
    import StoryUniqueValueProposition from '@/components/story/section/activity/content/uvp/StoryUniqueValueProposition.vue';
    import ContextualInlineGuide from '@/components/inline-guide/ContextualInlineGuide.vue';
    import skillsStackSrc from '@/assets/images/story/my-personal-skills.svg';
    import { computed, ref } from 'vue';
    import personalValuesSrc from '@/assets/images/story/personal-values.svg';
    import myCurrentChallengesSrc from '@/assets/images/story/my-current-challenges.svg';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { sectionStatus, showFeedbackQuestion } from '@/components/story/section/util';
    import StorySectionNotStarted from '@/components/story/section/StorySectionNotStarted.vue';
    import { StorySectionId, type StorySectionItem, StorySectionState } from '@/store/story/types';
    import StoryChallengePathCard from '@/components/story/StoryChallengePathCard.vue';
    import StoryGuide from '@/components/story/StoryGuide.vue';
    import { InlineGuideSection } from '@/store/inline-guide/types';
    import { useUIStoryStore } from '@/store/story/uiStoryStore';
    import StoryHeading from '@/components/story/heading/StoryHeading.vue';
    import StoryHeadingPrintOnly from '@/components/story/heading/StoryHeadingPrintOnly.vue';
    import StoryHomeAbout from '@/components/story/home/StoryHomeAbout.vue';

    const about = ref<HTMLElement | null>(null);

    function scrollToAbout() {
        about.value?.scrollIntoView({ behavior: 'smooth' });
    }

    const store = useStoryStore();

    const { isDesktop } = useIsDesktop();

    const uiStoryStore = useUIStoryStore();

    const whoIAm = computed((): StorySectionItem => {
        const section_statement = store.story?.whoIAm;
        return {
            id: StorySectionId.WhoIAm,
            title: 'Who I Am',
            description:
                'I bring my full self to work. ' +
                'My personal strengths are unique to me, and they are shaped ' +
                'by my personality type and the values that I hold.',

            statement: section_statement ?? null,
            image: personalValuesSrc,
            activities: store.whoIAmActivities,
            status: sectionStatus(store.whoIAmActivities),
            showFeedbackQuestion: section_statement ? showFeedbackQuestion(section_statement) : false,
        };
    });

    const whatIOffer = computed((): StorySectionItem => {
        const section_statement = store.story?.whatIOffer;
        return {
            id: StorySectionId.WhatIOffer,
            title: 'What I Offer',
            description:
                'My contribution to the workforce is unique. ' +
                "It's shaped by the experiences I've had and the skills I offer. " +
                'This is what makes me valuable.',

            statement: section_statement ?? null,
            image: skillsStackSrc,
            activities: store.whatIOfferActivities,
            status: sectionStatus(store.whatIOfferActivities),
            showFeedbackQuestion: section_statement ? showFeedbackQuestion(section_statement) : false,
        };
    });

    const whatIAmWorkingOn = computed((): StorySectionItem => {
        const section_statement = store.story?.whatIAmWorkingOn;
        return {
            id: StorySectionId.WhatIAmWorkingOn,
            title: "What I'm working on",
            description:
                "I'm addressing challenges that will help me grow, " +
                'and shaping my future career path. ' +
                'This is what I want support with.',

            statement: section_statement ?? null,
            image: myCurrentChallengesSrc,
            activities: store.whatIAmWorkingOnActivities,
            status: sectionStatus(store.whatIAmWorkingOnActivities),
            showFeedbackQuestion: section_statement ? showFeedbackQuestion(section_statement) : false,
        };
    });

    const showChallengePathCard = computed(() => {
        return (
            whatIOffer.value.status === StorySectionState.Completed &&
            whoIAm.value.status === StorySectionState.Completed &&
            whatIAmWorkingOn.value.status === StorySectionState.Completed
        );
    });

    const showUniqueValueProposition = computed(() => {
        return store.story?.uvp;
    });
</script>
