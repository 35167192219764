<template>
    <ConfettiExplosion
        class="confetti-explosion"
        :duration="5000"
        :stage-height="800"
        :force="1"
        :stage-width="isDesktop ? 1000 : 400"
        :particle-count="isDesktop ? 400 : 300"
    />
</template>

<script setup lang="ts">
    import ConfettiExplosion from 'vue-confetti-explosion';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { isDesktop } = useIsDesktop();
</script>

<style lang="scss">
    .confetti-explosion {
        position: fixed !important; /* or absolute */
        top: 0px;
        left: 50%;
    }
</style>
