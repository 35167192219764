<template>
    <v-container :class="responsiveClass" class="welcome-card pa-0">
        <div class="w-100 pa-0 welcome-card-top-image mb-8" style="position: relative" fluid>
            <v-row justify="space-around" class="bg-custom-neutral-grey-100" no-gutters>
                <v-img
                    :src="isDesktop ? overviewBannerDesktop : overviewBannerMobile"
                    color="white"
                />
            </v-row>
        </div>

        <v-container class="your-safe-space-card" fluid>
            <div class="text-center">
                <div class="text-heading-h1 align-center w-100 align-self-stretch mb-5">
                    We’ve set up a space just for you!
                </div>
                <div class="text-paragraph-xl">
                    Welcome onboard.  Actvo is a personal career planning platform, designed just
                    for you. It offers you a personal, unique workspace to make entirely your
                    own. Our process is proven and we will guide you through it.
                </div>
                <div class="text-paragraph-xl">
                    The more we know about you, the more we can personalise your experience. And
                    the more you will get out of Actvo.
                </div>
            </div>

            <div class="dot-divider">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                >
                    <circle cx="4" cy="4" r="4" fill="#FFC32C" />
                </svg>
            </div>

            <!-- WHY-->

            <div class="text-center">
                <div class="text-caption-subtitle" style="margin-bottom: 18px">Why</div>
                <div class="text-heading-h2 w-100" style="margin-bottom: 48px">
                    Self-direct your growth and accelerate your career!
                </div>
                <v-row class="why-three-cards justify-space-between" no-gutters style="gap: 20px">
                    <v-col class="d-flex" :cols="smAndDown ? 12 : ''">
                        <WelcomeItemCard
                            class="align-self-stretch"
                            type="why-card"
                            icon="search"
                            title="Gain clarity & confidence"
                            description="Because if you're clear on your worth, you will have more confidence
                                to ask for what you need to progress."
                        />
                    </v-col>
                    <v-col class="d-flex" :cols="smAndDown ? 12 : ''">
                        <WelcomeItemCard
                            type="why-card"
                            icon="arrow-up-right-dots"
                            title="Smash goals and gain new skills"
                            description="We get that work can be hard. We want to arm you with the knowledge
                                and skills you need to combat everyday challenges."
                        />
                    </v-col>
                    <v-col class="d-flex" :cols="smAndDown ? 12 : ''">
                        <WelcomeItemCard
                            type="why-card"
                            icon="compass"
                            title="Move your vision for your future forward"
                            description="There are so many opportunities at work now, but navigating to
                                what’s right for you is hard. We'll help you figure it out."
                        />
                    </v-col>
                </v-row>
            </div>

            <div class="dot-divider">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                >
                    <circle cx="4" cy="4" r="4" fill="#FFC32C" />
                </svg>
            </div>

            <!-- THE PROCESS -->

            <div class="text-center">
                <div class="text-caption-subtitle" style="margin-bottom: 18px">The process</div>
                <div class="text-heading-h1 w-100" style="margin-bottom: 48px">
                    What you’re going to be doing
                </div>

                <v-row class="the-process-cards" style="gap: 20px" no-gutters>
                    <v-col class="d-flex flex-column" :cols="smAndDown ? 12 : ''">
                        <HomeProcessCard
                            title="Crafting your Story"
                            description="Discovering hidden truths about you that will propel you forwards.
                                The more you share, the more we can help you to gain clarity on your unique value."
                            :order="1"
                        />
                    </v-col>
                    <v-col class="d-flex flex-column" :cols="smAndDown ? 12 : ''">
                        <HomeProcessCard
                            title="Solving everyday Challenges"
                            description="Lets figure out what might be holding you back at work, and explore
                                ways to solve challenges with creative activities. This is where the work starts!"
                            :order="2"
                        />
                    </v-col>
                    <v-col class="d-flex flex-column" :cols="smAndDown ? 12 : ''">
                        <HomeProcessCard
                            title="Designing a Growth Pathway"
                            description="Imagine knowing where your skills could take you? We want to help
                                you to choose the right career pathway that aligns to your values and your
                                goals."
                            :order="3"
                        />
                    </v-col>
                </v-row>
            </div>
        </v-container>

        <v-container class="meet-assistant-card" :class="responsiveClass" fluid>
            <v-row class="pa-0 text-center" no-gutters>
                <div class="text-caption-subtitle w-100" style="margin-bottom: 18px">
                    Your own assistant
                </div>
                <div class="text-heading-h1 w-100">Meet {{ ASSISTANT.username }}</div>
            </v-row>

            <v-row class="pa-0 text-center" no-gutters>
                <div class="meet-assistant-intro" :class="responsiveClass">
                    <app-guide-avatar size="165" class="pa-0" />
                    <div
                        class="text-paragraph-lg text-left text-custom-neutral-black"
                        style="flex: 1 1 0"
                    >
                        Hi, I’m {{ ASSISTANT.username }}! ! I'm trained as a careers strategist
                        and I'm always here for you to chat with and seek guidance. I will try to
                        give you suggestions and prompts along the way but wherever you see me,
                        let’s talk! Everything we discuss is private and my job is to fully support you 24/7.
                    </div>
                </div>
            </v-row>

            <v-row class="meet-assistant-three-cards justify-space-between ga-5" no-gutters>
                <v-col class="d-flex" :cols="smAndDown ? 12 : ''">
                    <WelcomeItemCard
                        type="guide-card"
                        icon="message"
                        title="Have a conversation"
                        :description="`${ASSISTANT.username} is a highly trained agent designed
                            to guide you through Actvo and answer any questions you have about your career.`"
                    />
                </v-col>
                <v-col class="d-flex" :cols="smAndDown ? 12 : ''">
                    <WelcomeItemCard
                        type="guide-card"
                        title="Contextual support"
                        icon="life-ring"
                        :description="`When you see ${ASSISTANT.username} popping up, it’s
                            because they have some important information for you that’s personal
                            to help you with what you’re working on.`"
                    />
                </v-col>
                <v-col class="d-flex" :cols="smAndDown ? 12 : ''">
                    <WelcomeItemCard
                        type="guide-card"
                        title="Here when you need"
                        icon="bullseye"
                        :description="`${ASSISTANT.username} is always on, 24/7 everyday. All
                            conversations are private and information stored securely. The more
                            you call on them, the more insights they have on you.`"
                    />
                </v-col>
            </v-row>
        </v-container>

        <StoryNextAreaCard
            class="cursor-pointer mb-16"
            :class="responsiveClass"
            @next-click="emit('next-click')"
            title="First up is building your profile"
            :image="myCurrentChallenges"
            color="primary-light"
        >
            <template #description>
                <p class="text-paragraph-md text-custom-neutral-900 px-0 mb-2">
                    We call it a 'Story' as it will change as your career grows. Take your time and
                    it will reveal to you some invaluable insights.
                </p>
                <p class="text-paragraph-md text-custom-neutral-900 px-0 mb-2">Let's go!</p>
            </template>
        </StoryNextAreaCard>
    </v-container>
</template>

<script setup lang="ts">
    import myCurrentChallenges from '@/assets/images/story/my-current-challenges.svg';
    import overviewBannerDesktop from '@/assets/images/onboarding/introducing-desktop.svg';
    import overviewBannerMobile from '@/assets/images/onboarding/introducing-mobile.svg';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useDisplay } from 'vuetify';
    import WelcomeItemCard from '@/components/welcome/WelcomeItemCard.vue';
    import HomeProcessCard from '@/components/common/home/HomeProcessCard.vue';
    import StoryNextAreaCard from '@/components/story/StoryNextAreaCard.vue';
    import { ASSISTANT } from '@/store/guide/types';

    const emit = defineEmits(['next-click']);
    const { smAndDown } = useDisplay();
    const { responsiveClass, isDesktop } = useIsDesktop();
</script>

<style scoped lang="scss">
    .your-safe-space-card {
        background: rgb(var(--v-theme-primary-light));
    }

    .welcome-card > div:last-child {
        margin-bottom: 112px !important;
    }

    .welcome-card {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .welcome-card.desktop {
        padding-bottom: 64px;
        padding-left: 80px;
        padding-right: 80px;
        align-items: center;

        .your-safe-space-card {
            border-radius: 32px;
            display: flex;
            padding: 48px 64px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 40px;
            align-self: stretch;
        }
    }

    .welcome-card.mobile {
        width: 100%;

        .welcome-card-top-image {
            width: 100%;
            height: 100%;
        }

        .your-safe-space-card {
            width: 100%;
            padding-top: 80px !important;
        }
    }

    .why-card,
    .the-process-card {
        background: white;
        border: 2px rgb(var(--v-theme-story-outline)) solid;
    }

    .meet-assistant-card {
        background: rgb(var(--v-theme-secondary-purple-light));
    }

    .why-card,
    .the-process-card,
    .meet-assistant-card {
        flex-direction: column;
        align-self: stretch;
        justify-content: flex-start;

        display: inline-flex;

        &.desktop {
            gap: 48px;
            display: flex;
            padding: 48px 64px;
            align-items: center;

            border-radius: 32px;
        }

        &.mobile {
            gap: 32px;
            display: flex;
            padding: 32px 16px;
            align-items: flex-start;

            border-radius: 8px;
        }
    }

    .meet-assistant-intro {
        &.desktop,
        &.mobile.md {
            width: 772px;
            gap: 32px;
            display: inline-flex;

            .meet-assistant-intro-avatar-wrapper {
                background: linear-gradient(180deg, #836eac 0%, #be96ba 100%);
                border-radius: 1500px;
                overflow: hidden;
                border: 3px #1638a5 solid;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }

        &.mobile.sm,
        &.mobile.xs {
            gap: 32px;
            display: block;

            margin-bottom: 48px;

            .meet-assistant-intro-avatar-wrapper {
                margin-bottom: 20px;
                overflow: hidden;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
    }

    .dot-divider {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
    }
</style>
