<template>
    <StoryContent />
    <PageActivity :area="UserActivityArea.Story" />
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import { useStoryStore } from '@/store/story/store';
    import StoryContent from '@/components/story/StoryContent.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const canvasStore = useCanvasStore();
    const activityStore = useActivityStore();
    const storyStore = useStoryStore();

    const { isDesktop } = useIsDesktop();

    activityStore.$reset();
    storyStore.$reset();

    await canvasStore.load();
    await activityStore.load();
    await storyStore.load();

    onMounted(async () => {
        await activityStore.addEntryActivity(UserActivityArea.Story);
    });

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.Story);
    });
</script>
