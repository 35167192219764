<template>
    <!--
        The layout is expected to wrap all the way to the left on the second line.
        A way to achieve this  is to add the label inside the the div
    -->
    <ul class="inline-list" :class="label ? 'has-label' : ''">
        <!-- Label as the first item -->
        <li class="label-list-item" v-if="label">
            <label class="text-paragraph-md font-weight-600"> {{ label }}: </label>
        </li>

        <!-- Tags -->
        <li v-for="(item, index) in items" :key="index">
            <div class="text-paragraph-md text-custom-neutral-grey-900">
                {{ item }}
            </div>
        </li>
    </ul>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const props = defineProps<{
        label?: string;
        items: string[];
    }>();

    const { responsiveClass } = useIsDesktop();

    type ListItem = { type: 'text'; content: string } | { type: 'separator' };
</script>

<style scoped lang="scss">
    // Expectations:
    // 1. The layout is expected to wrap all the way to the left on the second line
    // 2. The tags are separated by an ellipsis (marker in this case)
    // 3. Between the label and the first tag there is no ellipsis
    ul.inline-list {
        padding: 0;

        // Adding margin left to the whole ul list, which will be compensated by the first li item.
        // This is because the markers are added by the browser outside of the element div
        margin: 0 0 0 10px;
        display: flex; /* Flexbox displays items inline */
        flex-direction: row; /* Change to row for horizontal alignment */
        flex-wrap: wrap;
        row-gap: 8px;
        column-gap: 20px;
        align-items: center;
        align-content: center;
        align-self: stretch;
    }

    ul li::marker {
        padding-right: 16px;
        color: rgb(var(--v-theme-custom-neutral-grey-200)); /* Color for the bullet marker */
    }

    .inline-list.has-label {
        li:first-child,
        li:nth-child(2) {
            // Compensate margin for the first (label) and second (first tag) li item
            margin-left: -10px;
            list-style-type: none; /* Hides the marker */
        }
    }

    .inline-list:not(.has-label) {
        li:first-child {
            // Compensate margin for the first (label) and second (first tag) li item
            margin-left: -10px;
            list-style-type: none; /* Hides the marker */
        }
    }

    .inline-list li {
        display: list-item; /* Ensures items stay inline */
    }
</style>
