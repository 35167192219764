<template>
    <vue-advanced-chat
        ref="vacRef"
        height="100%"
        :current-user-id="user.id.toString()"
        :rooms="JSON.stringify(rooms)"
        :rooms-loaded="true"
        :single-room="true"
        :show-files="false"
        :show-audio="false"
        :show-emojis="false"
        :messages="JSON.stringify(store.displayableMessages)"
        :messages-loaded="store.messagesLoaded"
        @send-message="store.sendMessage($event.detail[0])"
        @fetch-messages="store.loadChat($event.detail[0])"
    >
        <!--
            Note: The slot global attribute  assigns a slot in a shadow DOM  shadow tree to an element
            This is not a vue directive.
        -->
        <div :slot="'room-header'">
            <span class="close-chat">
                <GuideDialogCloseButton @click="store.hideDialog()" class="pa-0" />
            </span>
        </div>

        <div :slot="'send-icon'">
            <v-btn
                variant="flat"
                width="40"
                class="pa-0"
                color="custom-neutral-grey-300"
                :loading="store.isWaitingForResponse"
            >
                <v-icon class="ml-3 mr-3" color="custom-purple-mid">mdi-send</v-icon>
            </v-btn>
        </div>

        <!--
           Note: The slot global attribute  assigns a slot in a shadow DOM  shadow tree to an element
           This is not a vue directive.

           Note 2: Adding :style="{ 'display': 'contents' } seems to do the trick
           for displaying messages at the right width. Without sequential messages will have a different width
       -->
        <div
            v-for="message in store.displayableMessages"
            :slot="dynamicSlotMessageName(message)"
            :key="message._id"
            :style="{ display: 'contents' }"
        >
            <div :slot="'message-avatar_' + message._id" v-if="!isCurrentUserMessage(message)">
                <GuideMessageAvatar :message="message" :user="props.user" />
            </div>

            <slot
                v-if="isCustomIdMessage(message)"
                :name="[message._id]"
                :key="`custom-message-${message._id}`"
            />
            <slot
                v-else-if="isSuggestionMessage(message)"
                :name="'suggestion-message'"
                :key="`suggestion-message-${message._id}`"
                :message="message"
                :user="props.user"
            />
            <GuideMessage
                v-else
                :message="message"
                :user="props.user"
                :key="`guide-message-${message._id}`"
            />

            <!-- detect if current user and if so show avatar here instead. With margin to the left -->
            <div
                :slot="'message-avatar_' + message._id"
                v-if="isCurrentUserMessage(message)"
                class="ml-2"
            >
                <GuideMessageAvatar :message="message" :user="props.user" />
            </div>
        </div>

        <!--        <div-->
        <!--            v-for="message in store.messages"-->
        <!--            :slot="dynamicSlotMessageName(message)"-->
        <!--            :key="message._id"-->
        <!--            class="w-100"-->
        <!--        >-->
        <!--            <GuideIntroMessage-->
        <!--                class="w-100"-->
        <!--                title="Select values that are important to you."-->
        <!--                submit-text="Let's get started"-->
        <!--            >-->
        <!--                {{ message.content }}-->
        <!--            </GuideIntroMessage>-->

        <!--            &lt;!&ndash;                <div v-if="message.system">System message: {{ message.content }}</div>&ndash;&gt;-->
        <!--            &lt;!&ndash;                <div v-else>Normal message: {{ message.content }}</div>&ndash;&gt;-->
        <!--        </div>-->
    </vue-advanced-chat>
</template>

<script setup lang="ts">
    import chatStyles from './chat-styles.css?inline';
    import { onBeforeUnmount, onMounted, ref } from 'vue';

    import { register } from 'vue-advanced-chat';
    import GuideDialogCloseButton from '@/components/guide/chat/GuideChatCloseButton.vue';
    import { useGuideStore } from '@/store/guide/store';
    import type { User } from '@/api/types/user';
    import GuideMessage from '@/components/guide/chat/GuideChatMessage.vue';
    import {
        ASSISTANT,
        GUIDE_MESSAGE_INTRO_ID,
        type GuideMessage as GuideMessageType,
        isSuggestionMessage,
    } from '@/store/guide/types';
    import { verify } from '@/store/verify';
    import GuideMessageAvatar from '@/components/guide/chat/GuideChatMessageAvatar.vue';
    import { useDisplay } from 'vuetify';

    const vacRef = ref(null);

    register();

    const store = useGuideStore();

    const props = defineProps<{
        user: User;
    }>();

    const { smAndDown } = useDisplay();

    const rooms = [
        {
            roomId: '1',
            roomName: 'Room 1',
            avatar: 'https://66.media.tumblr.com/avatar_c6a8eae4303e_512.pnj',
            users: [
                { _id: props.user.id.toString(), username: '' },
                { _id: ASSISTANT.id, username: ASSISTANT.username },
            ],
        },
    ];

    const autoScroll = JSON.stringify({
        send: {
            new: true,
            newAfterScrollUp: true,
        },
        receive: {
            new: true,
            newAfterScrollUp: true,
        },
    });

    const dynamicSlotMessageName = (message: any): string => {
        return `message_${message._id}`;
    };

    const addCustomStyle = () => {
        if (vacRef.value) {
            const style = document.createElement('style');
            style.textContent = chatStyles;
            const shadowRoot = verify((vacRef.value as Element).shadowRoot, 'no shadow root');
            shadowRoot?.appendChild(style);
        }
    };

    const isCustomIdMessage = (message: GuideMessageType): boolean => {
        return message._id === GUIDE_MESSAGE_INTRO_ID;
    };

    const isCurrentUserMessage = (message: GuideMessageType): boolean => {
        return message.senderId === props.user.id.toString();
    };

    onMounted(async () => {
        addCustomStyle();
    });

    onBeforeUnmount(() => {});
</script>

<style scoped lang="scss">
    .close-chat {
        position: absolute;
        right: 30px;
        top: 5px;
    }
</style>
