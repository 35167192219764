<template>
    <Loader class="fill-height" :title="theTitle" :subtitle="theSubtitle" />
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useLoadingStore } from '@/store/loadingStore';
    import Loader from '@/components/common/Loader.vue';

    /**
     * This component is a global loader that is used to show a loading state.
     * The content can be set in the global loadingStore.
     *
     * Use as #fallback in <Suspense />
     * ==================================
     *
     * This component is usually used as a #fallback component in a <Suspense /> component.
     *
     * Gotchas: Suspense Behavior with Dynamic Content & Reactivity
     *
     * The experience while developing using <Suspense /> - which is a experimental
     * feature: @see https://vuejs.org/guide/built-ins/suspense - is that it does
     * not automatically track store changes.
     *
     * If the store changes after Suspense resolves, the #fallback template does not get re-triggered.
     *
     * So, if the suspense is used like this, reactivity is lost:
     *  <Suspense timeout="0">
     *       <Loader
     *           class="fill-height"
     *           :title="loadingStore.title"
     *           :subtitle="loadingStore.subtitle"
     *       />
     *   </Suspense>
     *
     *  **To force vue to keep track of the global loadingStore title and subtitle properties,
     *  a separate compoennt that tracks computed properties come in place.**
     */

    /**
     *  Title coming from the global state. Keep as computed properties. Read comment above
     *
     *  To force vue to keep track of the global loadingStore
     */
    const theTitle = computed(() => {
        return useLoadingStore().title ?? 'Loading';
    });

    /**
     *  Subtitle coming from the global state. Keep as computed properties. Read comment above
     *
     *  **To force vue to keep track of the global loadingStore title and subttle properties,
     *  computed properties come in place.**
     */
    const theSubtitle = computed(() => {
        return useLoadingStore().subtitle ?? '';
    });
</script>
