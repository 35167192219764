<template>
    <ProfileCard title="Current employment">
        <v-card-text class="pa-0 text-wrap text-paragraph-md" v-if="userInputs">
            <v-text-field
                label="Current Company"
                variant="outlined"
                v-model="userInputs.current_company"
                :rules="[requiredRule('Current Company')]"
                validate-on="input"
            />

            <v-text-field
                label="Job Title"
                variant="outlined"
                v-model="userInputs.job_title"
                :rules="[requiredRule('Job Title')]"
                validate-on="input"
            />
        </v-card-text>
    </ProfileCard>
</template>

<script setup lang="ts">
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';
    import { requiredRule } from '@/components/profile/rules';
    import ProfileCard from '@/components/profile/ProfileCard.vue';

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);
</script>
