<template>
    <!-- Prevent rendering in case there was navigating away on beforeMount -->

    <AppGuideModal
        v-model="showNewSuccess"
        title="Well done for taking this first step toward overcoming your challenge!"
        action-text="Continue"
        @submit="onContinueAfterSave"
    >
        <template #default>
            Your challenge pathway is locked in! Check back in often to reflect on the plan you’ve
            made and track your progress. Remember I’m always here if you need help with any of your
            activities. I’m excited to see how you get on.
        </template>
    </AppGuideModal>

    <AppGuideModal
        v-model="showUpdateSuccess"
        title="Well done for updating your activities to make sure you stay on track!"
        action-text="Continue"
        @submit="onContinueAfterSave"
    >
        <template #default>
            Your challenge pathway has been updated! Check back in often to reflect on the plan
            you’ve made and track your progress. Remember I’m always here if you need help with any
            of your activities. I’m excited to see how you get on.
        </template>
    </AppGuideModal>

    <app-page-content>
        <template #intro>
            <app-page-content-intro title="Now make the activities your own">
                It’s important to set realistic goals for yourself, think about how much time you
                can commit to each of these activities and when you’d like to aim to have them
                complete.
            </app-page-content-intro>
        </template>

        <v-container
            class="pa-0 d-flex challenge-sequencing-container"
            v-for="(action, index) in selected || []"
            :key="`${index}`"
        >
            <app-sorting-controls
                :position="index + 1"
                :total="selected.length"
                @move-up="store.moveActionUp(index)"
                @move-down="store.moveActionDown(index)"
            />
            <SingleChallengeSequencingActionListItem :item="action" class="mb-2 w-100" />
        </v-container>
    </app-page-content>

    <app-page-footer>
        <template v-slot:actions>
            <app-page-tabs-footer-actions
                close-text="Back"
                @on-back="navigationStore.setPrevious"
                :title="store.isNew ? 'Save and begin' : 'Update challenge'"
                :submit-disabled="isSubmitDisabled"
                @on-submit="onSave"
            />
        </template>
    </app-page-footer>

    <PageActivity :area="UserActivityArea.ChallengePath" />
    <SingleChallengeSequencingGuide />
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';

    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { useRouter } from 'vue-router';
    import SingleChallengeSequencingActionListItem from '@/components/pathway/single-challenge/sequencing/SingleChallengeSequencingActionListItem.vue';
    import AppSortingControls from '@/components/common/AppSortingControls.vue';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import SingleChallengeSequencingGuide from '@/components/pathway/single-challenge/sequencing/SingleChallengeSequencingGuide.vue';
    import { storeToRefs } from 'pinia';
    import { verify } from '@/store/verify';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';

    const store = useSingleChallengePathStore();
    const activityStore = useActivityStore();

    const { selected } = storeToRefs(store);

    const showNewSuccess = ref(false);
    const showUpdateSuccess = ref(false);
    const router = useRouter();

    const navigationStore = useNavigationStore();
    const challenge = navigationStore.ctx.item;
    const challengeId = Number(verify(challenge.id, 'No challenge id'));

    const onSave = async () => {
        if (store.isNew) {
            await store.startChallenge();
            showNewSuccess.value = true;
        } else {
            await store.updateChallenge();
            showUpdateSuccess.value = true;
        }
    };

    const challengePathStore = useChallengePathStore();
    const onContinueAfterSave = async () => {
        await challengePathStore.load();
        await navigationStore.setTabByKey('select-challenge');
    };

    const isSubmitDisabled = computed(() => {
        // Some basic checks to ensure the selected actions are valid. Need to come back to this
        // I only focused on editable fields for now
        return store.selected.some(
            (action) =>
                !action.title ||
                !action.description ||
                !action.frequency ||
                action.duration_min === undefined ||
                !action.due_date,
        );
    });
</script>

<style lang="scss" scoped>
    .challenge-sequencing-container {
        min-height: 300px;
        height: fit-content;
    }
</style>
