<template>
    <ProfileCard
        title="Career confidence rating"
        subtitle="Thinking about your current role, how confident are you in it today?"
    >
        <v-card-text class="pa-0 text-wrap text-paragraph-md">
            <v-rating
                v-model="userInputs.current_company_confidence_rating"
                :max="5"
                :step="0.5"
                :readonly="false"
                half-increments
                hover
                size="x-large"
                color="primary"
                :rules="[requiredRule('Current Company Career Confidence')]"
                :hide-details="false"
                validate-on="input"
                label="Current Company Career Confidence"
                class="border-1-custom-neutral-grey-400 rounded-4"
            />
        </v-card-text>
    </ProfileCard>
</template>

<script setup lang="ts">
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';
    import { requiredRule } from '@/components/profile/rules';
    import ProfileCard from '@/components/profile/ProfileCard.vue';

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);
</script>
