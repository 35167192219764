<template>
    <v-card class="rounded-8 pa-4 bg-white app-card box-shadow-none">
        <v-card-title class="pa-2 text-subtitle-1 font-weight-regular text-wrap">
            Describe why it was memorable and what you learned about yourself.
        </v-card-title>
        <v-card-text class="mx-0 px-2 text-paragraph-md">
            <v-text-field
                variant="outlined"
                v-model="current!.title"
                label="Title"
                persistent-placeholder
                placeholder="What was the memorable experience?"
            />

            <v-textarea
                variant="outlined"
                :counter="true"
                :persistent-counter="true"
                rows="6"
                :rules="[
                    (v) =>
                        (v || '').length <= DESCRIPTION_MAX_LIMIT ||
                        'Description must be 200 characters or less',
                ]"
                v-model="current!.description"
                persistent-placeholder
                placeholder="Record briefly why this experience was so memorable, and what you learned."
                label="Description"
            />

            <v-row no-gutters>
                <span class="font-weight-600 mb-1">Reflecting on this experience, was it rewarding or difficult?</span>
                <span class="font-weight-400">Choose a value that best describes how positive or negative the experience was.</span>
            </v-row>
            <v-row no-gutters class="mt-2 mb-n4">
                <v-slider
                    v-model="current!.enjoyment"
                    step="1"
                    max="5"
                    min="-5"
                    :color="enjoymentColor"
                    :track-color="enjoymentColor"
                    :track-fill-color="enjoymentColor"
                    class="ml-0 enjoyment-slider"
                >
                    <template v-slot:prepend>
                        <v-icon color="black" style="opacity: 1">mdi-thumb-down</v-icon>

                    </template>
                    <template v-slot:append>
                        <v-icon color="black" style="opacity: 1">mdi-thumb-up</v-icon>

                    </template>
                </v-slider>
            </v-row>
            <v-row class="w-100 text-caption" no-gutters>
                <v-col cols="4">
                    <span>Negative experience</span>
                </v-col>
                <v-col cols="8" class="text-right">
                    <span>Positive experience</span>
                </v-col>
            </v-row>

        </v-card-text>

        <app-notification
            v-if="
                current!.status === LearnedExperienceItemState.Error &&
                (current!.prevStatus === LearnedExperienceItemState.Updating ||
                    current!.prevStatus === LearnedExperienceItemState.Creating)
            "
            title="There was an error"
            message="We could not save your changes at this time. Try again."
            variant="error"
            :animate-to="true"
        />

        <v-card-actions>
            <SubmitButton
                :disabled="!isSubmitEnabled"
                text="Save"
                @click="onSaveClick"
                :loading="
                    current!.status === LearnedExperienceItemState.Updating ||
                    current!.status === LearnedExperienceItemState.Creating ||
                    current!.status === LearnedExperienceItemState.Deleting
                "
            />
            <app-button variant="flat" @click="onEditCancel" v-if="!store.isEditing">
                Cancel
            </app-button>
            <v-spacer />
            <app-button v-if="current?.id" @click="onDelete"> Delete</app-button>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import { computed, ref } from 'vue';
    import { useLearnedExperiencesStore } from '@/store/learned-experiences/store';
    import { storeToRefs } from 'pinia';
    import { verify } from '@/store/verify';
    import { getEnjoymentColor } from '@/components/canvas/learned-experiences/util';
    import AppNotification from '@/components/common/AppNotification.vue';
    import { LearnedExperienceItemState } from '@/store/learned-experiences/types';

    const isDeleting = ref(false);

    const DESCRIPTION_MAX_LIMIT = 200;

    const store = useLearnedExperiencesStore();
    const { current } = storeToRefs(store);

    const isSubmitEnabled = computed(() => {
        const value = verify(current.value, 'current.value must exist while editing');

        return (
            value.title?.length !== undefined &&
            value.title?.length > 0 &&
            value.description?.length !== undefined &&
            value.description?.length > 0 &&
            value.description?.length <= DESCRIPTION_MAX_LIMIT &&
            value.enjoyment !== null &&
            !!value.user_job_id
        );
    });

    const enjoymentColor = computed(() => {
        return getEnjoymentColor(current.value?.enjoyment);
    });

    const getSliderLabel = (modelValue: number) => {
        switch (modelValue) {
            case -5:
                return 'Very negative';
            case -4:
                return 'Very negative';
            case -3:
                return 'Moderately negative';
            case -2:
                return 'Moderately negative';
            case -1:
                return 'Slightly negative';
            case 0:
                return 'Neutral';
            case 1:
                return 'Slightly positive';
            case 2:
                return 'Moderately positive';
            case 3:
                return 'Moderately positive';
            case 4:
                return 'Very positive';
            case 5:
                return 'Very positive';
            default:
                return '';
        }
    };

    const onSaveClick = async () => {
        await store.saveLearnedExperience();
    };

    const onDelete = async () => {
        await store.deleteCurrent();

        if (!store.current) {
            isDeleting.value = false;
        }
    };

    const onEditCancel = () => {
        store.cancelEdit();
    };
</script>

<style lang="scss">
    .enjoyment-slider {
        color: linear-gradient(180deg, #ffc32b 0%, #fb8625 100%) !important;
    }

    .v-slider-thumb__label {
        width: max-content;
    }
</style>
