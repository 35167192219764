<template>
    <v-container class="card" :class="type">
        <div class="card__image text-center d-flex">
            <v-img :src="image" width="202" height="202" />
        </div>
        <div class="card__content" :class="responsiveClass">
            <div
                class="card__content__title align-center d-flex text-heading-h1 align-self-stretch text-left text-custom-neutral-black mb-2 overflow-visible"
            >
                {{ title }}

                <app-fa-icon
                    icon="fas fa-arrow-right"
                    class="ml-4"
                    :class="`text-${type}-outline`"
                />
            </div>
            <div
                class="card__content__description text-left text-paragraph-md text-custom-neutral-700"
            >
                {{ description }}
            </div>
        </div>
        <div
            class="ma-0 w-100 align-center text-paragraph-sm font-weight-600 cursor-pointer"
            :class="isDesktop ? 'text-center' : 'text-left'"
            @click="emit('click')"
        >
            <span v-if="disabled">Coming soon</span>
            <span v-else>Learn more</span>
            <app-fa-icon icon="fa-arrow-down" class="ml-4" />
        </div>
    </v-container>
</template>
<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { isDesktop, responsiveClass } = useIsDesktop();

    defineProps<{
        type: 'story' | 'challenges' | 'growth';
        image: string;
        title: string;
        description: string;
        disabled?: boolean;
    }>();

    const emit = defineEmits(['click']);
</script>

<style lang="scss" scoped>
    .card {
        &.story {
            --border-color: rgb(var(--v-theme-story-outline));
            --background-color: rgb(var(--v-theme-story-primary-light));
        }

        &.challenges {
            --border-color: rgb(var(--v-theme-challenges-outline));
            --background-color: rgb(var(--v-theme-challenges-primary-light));
        }

        &.growth {
            --border-color: rgb(var(--v-theme-growth-outline));
            --background-color: rgb(var(--v-theme-growth-primary-light));
        }

        display: flex;
        padding: 32px 24px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 0;
        gap: 32px;

        border-radius: 16px;
        border: 2px solid var(--border-color);
        background: var(--background-color);
    }
</style>
