<template>
    <div class="process-card__order w-100 text-center" :class="responsiveClass">
        <div class="process-card__order__content  text-heading-h3" :class="responsiveClass">
            {{ order }}
        </div>
    </div>
    <v-container class="process-card d">
        <div class="the-process-card-content" :class="responsiveClass">
            <div class="text-heading-h3 text-center w-100 mb-2">{{ title }}</div>
            <div class="text-paragraph-sm text-center w-100 align-self-stretch">
                {{ description }}
            </div>
        </div>
    </v-container>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { responsiveClass } = useIsDesktop();

    defineProps<{
        order: number;
        title: string;
        description: string;
    }>();
</script>

<style scoped lang="scss">
    .process-card {
        display: flex;
        padding: 64px 20px 48px 20px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        flex: 1 0 0;
        border-radius: 8px;
        border: 2px solid rgb(var(--v-theme-primary));
        background: white;
    }

    .process-card__order {
        position: relative;
        justify-content: center;
        display: flex;

        &.desktop {
            height: 24px;
        }

        &.mobile {
            height: 20px;
        }
    }

    .process-card__order__content {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        border-radius: 100px;
        background: rgb(var(--v-theme-primary));

        &.desktop {
            width: 48px;
            height: 48px;
        }

        &.mobile {
            width: 40px;
            height: 40px;
        }
    }
</style>
