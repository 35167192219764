<template>
    <v-card
        color="custom-neutral-grey-600"
        class="pa-4"
        variant="outlined"
    >
        <p class="w-100 text-capitalize text-left text-paragraph-lg font-weight-medium">
            Add another
        </p>
    </v-card>
</template>
