<template>
    <v-card min-height="80" class="rounded-8 pa-5 bg-white app-card box-shadow-none">
        <v-card-text class="mx-0 pa-0 text-paragraph-md">
            <v-row no-gutters>
                <v-col cols="12">
                    <v-text-field
                        :disabled="isSaving"
                        variant="outlined"
                        v-model="current!.description"
                        label="Strength"
                        :rules="[store.isAddingNew ? skillNewIsValidRule : skillEditIsValidRule]"
                        persistent-placeholder
                        placeholder="What's the name of this personal strength?"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="pa-0">
            <SubmitButton
                text="Save"
                :disabled="store.isAddingNew && !isSubmitEnabled"
                @click="onSaveClick"
            />
            <CloseButton variant="plain" @click="store.cancelEdit" text="Cancel" />
            <v-spacer />
            <app-button
                v-if="!store.isAddingNew"
                variant="plain"
                prepend-icon="mdi-close"
                @click="onDelete"
                >Remove strength
            </app-button>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, onBeforeMount, ref } from 'vue';
    import { storeToRefs } from 'pinia';
    import { verify } from '@/store/verify';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { type EditableSkill, SkillType } from '@/store/skills/types';
    import type { SwitchOption } from '@/components/canvas/skills/util';
    import { type CanvasSkill, isCanvasSkill } from '@/api/types/canvas/skills';
    import { cloneDeep } from 'lodash';
    import { usePersonalStrengthsStore } from '@/store/personal-strengths/store';
    import SubmitButton from '@/components/footer-actions/SubmitButton.vue';
    import CloseButton from '@/components/footer-actions/CloseButton.vue';

    const store = usePersonalStrengthsStore();
    const { current } = storeToRefs(store);

    const { isDesktop } = useIsDesktop();

    const originalValue = ref<EditableSkill | null>(null);

    const onChangeType = (option: SwitchOption) => {
        current.value!.type = option.value as SkillType;
    };

    onBeforeMount(() => {
        originalValue.value = cloneDeep(current.value!);
    });

    const onDelete = async () => {
        try {
            isSaving.value = true;
            const value = current.value;
            if (value) {
                await store.removePersonalStrength(value as unknown as CanvasSkill);
            }
        } finally {
            isSaving.value = false;
        }
    };

    const isSubmitEnabled = computed(() => {
        const value = verify(current.value, 'current.value must exist while editing');

        const requiredData =
            value.type && value.description?.length !== undefined && value.description?.length > 0;

        if (requiredData) {
            if (store.isAddingNew) {
                return skillNewIsValidRule(value.description) === true;
            } else {
                const original = verify(
                    originalValue.value,
                    'originalValue must exist while editing',
                );
                if (
                    original.type !== value.type ||
                    (value.description !== original.description &&
                        skillEditIsValidRule(value.description) === true)
                ) {
                    return true;
                }
            }
        }

        return false;
    });

    const isSaving = ref(false);

    const onSaveClick = async () => {
        try {
            isSaving.value = true;
            if (store.isAddingNew) {
                await store.createPersonalStrength(current.value!);
            } else {
                await store.updatePersonalStrength(current.value!);
            }
        } finally {
            isSaving.value = false;
        }
    };

    const skillNewIsValidRule = (v: string) => {
        if (v.length === 0) {
            return 'This field is required';
        }

        let error = null;
        store.values.forEach((item) => {
            if (item.description === v) {
                error = 'You already added this personal strength';
                return;
            }
        });

        return error ?? true;
    };

    const skillEditIsValidRule = (v: string) => {
        if (v.length === 0) {
            return 'This field is required';
        }

        let error = null;
        store.values.forEach((item) => {
            if (item.description === v) {
                if (
                    isCanvasSkill(item) &&
                    isCanvasSkill(current.value) &&
                    item.id !== current.value.id
                ) {
                    error = 'You already added this personal strength';
                    return;
                }
                if (
                    isCanvasSkill(item) &&
                    isCanvasSkill(current.value) &&
                    item.id !== current.value.id
                ) {
                    error = 'You already added this personal strength';
                    return;
                }
            }
        });

        return error ?? true;
    };
</script>

<style lang="scss">
    .v-card.app-card {
        border: 1px dashed;
        border-color: rgb(var(--v-theme-custom-neutral-grey-700));
        border-image-slice: 4;
    }
</style>
