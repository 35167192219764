<template>
    <v-container fluid class="bg-white ma-0 d-print-only pa-0 story-heading-print-only">
        <!--
               Note: This header in only printed when using @media print
               Ideally, we should override only the print styles in the component,
               but given both components are not very similar, a new layout is implemented.
           -->
        <v-row
            no-gutters
            class="mx-0 mb-1 w-100 d-print-only-flex align-center story-heading-print-only--container"
            v-if="activityStore.lastCompleted"
        >
            <v-col cols="6">
                <div class="text-heading-h1 story-printing-heading my-0">
                    {{ storyOwner }} story
                </div>
            </v-col>
            <v-col cols="6">
                <div class="d-flex justify-end align-center story-header-info">
                    <div class="story-header-activity me-4">
                        <span
                            class="font-weight-regular text-paragraph-sm text-custom-neutral-grey-700 d-block"
                        >
                            Last activity
                        </span>
                        <div class="text-paragraph-sm font-weight-600">
                            {{ lastCompletedDate }}
                        </div>
                    </div>
                    <div class="story-header-completed me-4">
                        <span
                            class="font-weight-regular text-paragraph-sm text-custom-neutral-grey-700 d-block"
                        >
                            Last completed
                        </span>
                        <div class="text-paragraph-sm font-weight-600">
                            {{ lastCompletedActivityLabel }}
                        </div>
                    </div>
                    <div class="story-header-logo">
                        <LogoCircle height="73" width="73" :eager="true" />
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useUsersStore } from '@/store/user/store';
    import LogoCircle from '@/components/header/PrintLogoCircle.vue';

    const activityStore = useActivityStore();
    const userStore = useUsersStore();

    const lastCompletedDate = computed(() => {
        return activityStore.lastCompletedDate;
    });

    const lastCompletedActivityLabel = computed(() => {
        return activityStore.lastCompletedActivityLabel;
    });

    const storyOwner = computed(() => {
        return userStore.displayName ? `${userStore.displayName}'s` : 'Your';
    });
</script>
