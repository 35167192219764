<template>
    <PlanPreviewLayout
        :to="navigateTo"
        class="fill-height"
        :image-src="imageSrc"
        title="My Career Plan"
        :submit-title="submitTitle"
    >
        <template v-slot:default>
            <div>
                <p class="mb-2">
                    Crafting a career plan is often challenging, that's why we’re here to help!
                </p>
                <p class="mb-2">
                    We’d like to help you map out some actions to help you achieve your future
                    career goal, and support you with the resources and advice that you need to
                    achieve it.
                </p>
                <p class="mb-2">
                    We will use the Future Aspiration statement you wrote in your story as a
                    starting point, and map out a roadmap to help you get there.
                </p>

                <p class="mb-2">
                    {{ ASSISTANT.username }} is here to help and can make suggestions for skills and resources you might
                    need - let's go!
                </p>

                <v-banner
                    v-if="!canNavigateToPlan"
                    class="mt-4 font-weight-medium"
                    icon="mdi-chat-alert"
                    color="yellow"
                >
                    You haven’t completed your Future Aspiration in your Story. Complete that first,
                    and then we can work on your Career Plan.
                </v-banner>
            </div>

            <div></div>

            <br />
            <br />
        </template>
    </PlanPreviewLayout>
</template>

<script setup lang="ts">
    import imageSrc from '@/assets/images/canvas/preview/future-aspiration.svg';
    import { futureAspirationPlanRoute, futureAspirationRoute } from '@/router/routes';
    import PlanPreviewLayout from '@/components/plan/preview/PlanPreviewLayout.vue';
    import { computed } from 'vue';
    import { useCareerPlanStore } from '@/store/plan/career/store';
    import { ASSISTANT } from '@/store/guide/types';

    const store = useCareerPlanStore();

    const canNavigateToPlan = computed(() => {
        return store.aspiration !== null;
    });

    const navigateTo = computed(() => {
        if (canNavigateToPlan.value) {
            return futureAspirationPlanRoute;
        } else {
            return futureAspirationRoute;
        }
    });

    const submitTitle = computed(() => {
        if (canNavigateToPlan.value) {
            return 'See my career plan';
        } else {
            return 'Set my future aspiration';
        }
    });
</script>
