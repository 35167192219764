<template>
    <v-row justify="space-around" class="text-heading-h5 font-weight-700" no-gutters> Career plan</v-row>

    <v-row justify="space-around" class="text-center my-4" no-gutters>
        Now that you’ve selected your vision for your career in the future, let’s create a pathway
        to get you there.
    </v-row>

    <v-container class="bg-custom-neutral-grey-100">
        <v-row justify="space-around" class="text-heading-h6 font-weight-700 mb-8" no-gutters>
            Your chosen future goal is
        </v-row>

        <v-row no-gutters class="mt-4" justify="center" v-if="store.aspiration">
            <AspirationCard :item="store.aspiration" class="mx-4" />
        </v-row>

        <v-row no-gutters class="my-4" justify="center" v-if="store.aspiration">
            <AspirationDescription />
        </v-row>

        <v-row no-gutters class="mt-12" justify="center" v-if="store.aspiration">
            <div class="w-75 text-center">
                Let’s set some activities you might want to complete over the coming months.
                <br />
                We’ve set your activities into 3 phases: <b>Planning</b>, <b>Preparing</b>, and
                <b>Transitioning</b>. We recommend you work backwards planning purposes.
            </div>
        </v-row>

        <v-divider class="mt-16" thickness="2" color="custom-purple-deep" />

        <v-row no-gutters class="mt-4">
            <v-col
                :cols="lgAndUp ? 4 : md ? 6 : 12"
                v-for="(stage, index) in store.stages"
                class="pa-0 mb-16"
                :key="index"
            >
                <CareerPlanStage :value="stage" />

                <v-divider v-if="smAndDown" class="mt-16 mx-n4" color="custom-purple-deep" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { useDisplay } from 'vuetify';
    import AspirationCard from '@/components/plan/career-plan/AspirationCard.vue';
    import { useCareerPlanStore } from '@/store/plan/career/store';
    import AspirationDescription from '@/components/plan/career-plan/AspirationDescription.vue';
    import CareerPlanStage from '@/components/plan/career-plan/CareerPlanStage.vue';

    const { lgAndUp, md, smAndDown } = useDisplay();

    const store = useCareerPlanStore();
</script>
