<template>
    <v-app-bar
        class="app-page-navigation"
        :class="responsiveClass"
        flat
        :floating="true"
        :height="height"
    >
        <div
            class="d-flex align-center app-page-navigation-container px-5 py-2 w-100"
            :class="responsiveClass"
        >
            <span
                v-if="title ?? sectionTitle"
                class="text-paragraph-md pl-0 py-0 align-center app-page-navigation-container-title"
            >
                {{ title ?? sectionTitle }}
            </span>
            <span
                v-else-if="hasSlot('title')"
                class="text-paragraph-md pl-0 py-0 align-center app-page-navigation-container-title"
            >
                <slot name="title" />
            </span>
            <v-spacer />
            <app-button variant="outlined" @click="emit('show-tutorial')" v-if="!hideTutorial">
                Tutorial
            </app-button>
        </div>
    </v-app-bar>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useSlots } from 'vue';

    const emit = defineEmits(['show-tutorial']);

    withDefaults(
        defineProps<{
            title?: string;
            sectionTitle?: string;
            hideTutorial?: boolean;
        }>(),
        {
            title: '',
        },
    );

    const slots = useSlots();

    const hasSlot = (name: string) => {
        return !!slots[name];
    };

    const height = 48;

    const { responsiveClass } = useIsDesktop();
</script>

<style lang="scss" scoped>
    .app-page-navigation {
        .app-page-navigation-container {
            border-bottom: 1px solid rgb(var(--v-theme-outline));
        }
    }
</style>
