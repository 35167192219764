import { computed } from 'vue';
import { defineStore } from 'pinia';
import { useRoute, useRouter } from 'vue-router';
import {
    careerHistoryRoute,
    challengePathwaysRoute,
    challengesHomeRoute,
    challengeActivityRoute,
    type DeclaredRoute,
    homeRoute,
    memorableExperienceRoute,
    personalityTypeRoute,
    personalStrengthRoute,
    personalValuesRoute,
    skillsRoute,
    storyRoute,
} from '@/router/routes';

export const useURLStore = defineStore('url-store', () => {
    const router = useRouter();
    const route = useRoute();

    /** Update the query parameters in the URL, preserving existing ones. */
    const updateQueryParams = async (queryParams: any) => {
        await router.replace({
            query: {
                ...route.query,
                ...queryParams, // Add or update the parameter
            },
        });
    };

    /** Remove a specific query param, without triggering a refresh */
    const removeQueryParam = async (param: string) => {
        // Create a copy of the current query parameters
        const query = { ...route.query };

        // Remove the specific query parameter
        delete query[param];

        // Push the updated query parameters back to the router
        await router.replace({
            query,
        });
    };

    const _isCurrent = (r: DeclaredRoute) => {
        return r.name === route.name;
    };

    const isHomePath = computed(() => {
        return _isCurrent(homeRoute);
    });

    const isStoryPath = computed(() => {
        return [
            storyRoute,
            skillsRoute,
            memorableExperienceRoute,
            careerHistoryRoute,
            personalValuesRoute,
            personalStrengthRoute,
            personalityTypeRoute,
        ].some((route) => {
            return _isCurrent(route);
        });
    });

    const isChallengePath = computed(() => {
        return (
            _isCurrent(challengesHomeRoute) ||
            _isCurrent(challengePathwaysRoute) ||
            _isCurrent(challengeActivityRoute)
        );
    });

    return {
        updateQueryParams,
        removeQueryParam,

        isCurrent: _isCurrent,
        isHomePath,
        isStoryPath,
        isChallengePath,
    };
});
