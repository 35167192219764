<template>
    <app-page-content>
        <template v-slot:intro>
            <app-page-content-intro title="Select a challenge">
                Choose a challenge so that we can build a plan of activities to help you solve it.

                <br />
                <br />
                We suggest you start with something easy.
            </app-page-content-intro>
        </template>

        <v-container class="pa-0 d-flex flex-column ga-8">
            <v-container class="pa-0" v-if="store.inProgressChallenges.length">
                <div class="text-heading-h3">In progress</div>
                <v-card variant="flat" class="pa-0">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.inProgressChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            @update="onUpdateInProgressClick(item)"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0" v-if="store.notStartedChallenges.length">
                <div class="text-heading-h3">Not started</div>
                <v-card variant="flat" class="pa-0">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.notStartedChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            @start="onStartClick(item)"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0" v-if="store.completedChallenges.length">
                <div class="text-heading-h3">Completed</div>
                <v-card variant="flat" class="pa-0">
                    <v-card-text class="pa-0 mt-5">
                        <PathwayChallengeListItem
                            v-for="(item, index) in store.completedChallenges"
                            :key="index"
                            :item="item"
                            class="mb-2"
                            :is-editable="false"
                        />
                    </v-card-text>
                </v-card>
            </v-container>

            <v-container class="pa-0" v-if="store.deactivatedChallenges.length">
                <v-expansion-panels>
                    <v-expansion-panel elevation="0">
                        <v-expansion-panel-title class="pa-0 text-paragraph-md font-weight-600">
                            <div class="text-heading-h3 align-center d-flex w-100">
                                Archived
                                <v-tooltip
                                    location-strategy="connected"
                                    content-class="bg-white text-neutral-black text-paragraph-md pa-5 elevation-3"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-icon v-bind="props" size="20" class="ml-2"
                                        >mdi-help-circle
                                        </v-icon>
                                    </template>
                                    These pathways have been archived in the past. They can be
                                    unarchived at any time.
                                </v-tooltip>
                            </div>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text class="pa-0">
                            <PathwayChallengeListItem
                                v-for="(item, index) in store.deactivatedChallenges"
                                :key="index"
                                :item="item"
                                class="mb-2"
                                :is-editable="true"
                                @unarchive="onArchiveClick(item)"
                            />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-container>
        </v-container>


    </app-page-content>

    <SingleChallengePathUnarchive v-if="archiveItem" v-model="showArchive" :item="archiveItem" />
</template>

<script setup lang="ts">
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';
    import AppPageContentIntro from '@/components/common/app-page/AppPageContentIntro.vue';
    import PathwayChallengeListItem from '@/components/pathway/PathwayChallengeListItem.vue';
    import SingleChallengePathUnarchive from '@/components/pathway/single-challenge/SingleChallengePathUnarchive.vue';
    import { ref } from 'vue';
    import type { CanvasCurrentChallenge } from '@/api/types/canvas/currentChallenge';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';

    const store = useChallengePathStore();

    const showArchive = ref(false);
    const archiveItem = ref<CanvasCurrentChallenge | null>(null);
    const onArchiveClick = (item: CanvasCurrentChallenge) => {
        archiveItem.value = item;
        showArchive.value = true;
    };

    const navigationStore = useNavigationStore();

    const onUpdateInProgressClick = async (item: CanvasCurrentChallenge) => {
        await navigationStore.setTabByKey('progress', { item });
    };

    const onStartClick = async (item: CanvasCurrentChallenge) => {
        await navigationStore.setTabByKey('activities', { item });
    };
</script>
