import {
    type StorySection,
    type StoryActivity,
    StoryActivityStatus,
    StorySectionState,
} from '@/store/story/types';
import type { ApiStoryStatement } from '@/api/types/canvas/apiStory';

export const sectionStatus = (activities: StoryActivity[]): StorySectionState => {
    // if all activities are completed, return completed
    // also if there are no activities returns completed
    const completed = activities.every(
        (activity) => activity.status === StoryActivityStatus.Completed,
    );
    if (completed) {
        return StorySectionState.Completed;
    }

    // if all activities are not started, return not started
    const notStarted = activities.every(
        (activity) => activity.status === StoryActivityStatus.NotStarted,
    );
    if (notStarted) {
        return StorySectionState.NotStarted;
    }

    // otherwise, return in progress
    return StorySectionState.InProgress;
};

export function showFeedbackQuestion(section: ApiStoryStatement): boolean {
    if (section.feedback === null || section.feedback === undefined) {
        return true;
    }

    return !section.feedback;
}
