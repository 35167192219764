<template>
    <!-- v-select for Options -->
    <v-select
        density="compact"
        color="neutral-grey"
        width="160"
        :model-value="selectedOption"
        @update:model-value="onOptionChange"
        :items="options"
        label="Options"
        variant="outlined"
        class="mt-4"
    >
        <template #item="{ item, props }">
            <v-divider v-if="item.raw.type === 'divider'" />
            <v-list-item v-else v-bind="props" :title="item.title" :value="item.value" />
        </template>
    </v-select>

    <SingleChallengePathArchive
        v-if="store.challenge"
        v-model="showArchive"
        :item="store.challenge" />
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import SingleChallengePathArchive from '@/components/pathway/single-challenge/SingleChallengePathArchive.vue';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';

    const store = useSingleChallengePathStore();
    const router = useRouter();

    const navigationStore = useNavigationStore();

    const showArchive = ref(false);

    const options = [
        { title: 'Edit activities', value: 'edit-activities' },
        { title: 'Edit approach and sequencing', value: 'edit-sequencing' },
        { type: 'divider' },
        { title: 'Archive', value: 'archive' },
    ];
    const selectedOption = ref<string | null>(null);

    // Handle option change
    const onOptionChange = async (value: string) => {
        switch (value) {
            case 'edit-activities':
                await navigationStore.setTabByKey('activities');
                break;
            case 'edit-sequencing':
                await navigationStore.setTabByKey('approach');
                break;
            case 'archive':
                showArchive.value = true;
                break;
            default:
                break;
        }
    };
</script>

<style scoped lang="scss">
    :deep(.v-label.v-field-label) {
        color: rgb(var(--v-theme-neutral-grey));
        opacity: 1;
    }
</style>
