export type NavigationTabItemFooterPreviousConfig = {
    /** Allows to override default text ('Back') */
    text?: string;

    /** Allows to override, if you want to skip normal flow to the previous tab */
    to?: any;
};

export type NavigationTabItemFooterPrevious = NavigationTabItemFooterPreviousConfig

export const makePrevious = (
    config?: NavigationTabItemFooterPreviousConfig,
): NavigationTabItemFooterPrevious => {
    return {
        text: config?.text,
        to: config?.to,
    };
};

export const makeNext = (
    config?: NavigationTabItemFooterSubmitConfig,
): NavigationTabItemFooterSubmit => {
    return {
        text: config?.text,
        to: config?.to,
        disabled: config?.disabled,
        tooltip: config?.tooltip,
        loading: config?.loading,
    };
};

export const makeDefault = (
    config?: NavigationTabItemFooterPreviousConfig,
): NavigationTabItemFooterPrevious => {
    return makePrevious({});
};

export type NavigationTabItemFooterSubmit = {
    /**
     * If returns true it continue with the execution.
     * If returns false it will stop.
     */
    cb?: () => Promise<boolean>;

    /**
     * If provided it will navigate to this URL after the cb is executed (if cb is provided)
     * If param is not provided, it will navigate to the next tab
     */
    to?: any;

    disabled?: () => boolean;
    loading?: () => boolean;
    tooltip?: string;

    text?: string;
};

export type NavigationTabItemFooterSubmitConfig = NavigationTabItemFooterSubmit;

export type NavigationTabItemFooter = {
    /**
     * Allows to override previous config
     * If false: it will not show
     * If {}: it will show with default text and default behaviour
     */
    prev?: NavigationTabItemFooterPrevious;

    /**
     * Allows to override previous config
     * If false: it will not show
     * If {}: it will show with default text and default behaviour
     */
    next?: NavigationTabItemFooterSubmit;
};

export type NavigationTabItemConfig = {
    title: string;
    key: string;
    to?: string;

    completed?: () => boolean;

    disabled?: () => boolean;
    onSelect?: () => Promise<void>;

    default?: boolean;

    /**
     * If not defined, the footer will not be shown.
     * Also, allows a boolean definition. This allows to make an explicit declaration
     * when the footer: false and leave a comment there for understanding.
     */
    footer?: NavigationTabItemFooter | boolean;
};

export type NavigationTabItem = NavigationTabItemConfig & {
    order: number;
    footer?: NavigationTabItemFooter
};
