<template>
    <div
        v-if="title"
        class="text-heading-h1 w-100 text-left"
        :class="!hasSlot('default') ? 'mb-10' : ''"
    >
        {{ title }}
    </div>
    <div v-else-if="hasSlot('title')" :class="!hasSlot('default') ? 'mb-10' : ''">
        <slot name="title" />
    </div>

    <div class="text-left mt-5 mb-10 w-100 text-paragraph-md" v-if="hasSlot('default')">
        <slot />
    </div>
</template>

<script setup lang="ts">
    import { useSlots } from 'vue';

    const emit = defineEmits(['show-tutorial']);

    withDefaults(
        defineProps<{
            width?: number;
            title?: string;
        }>(),
        {
            title: '',
        },
    );

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>
