<template>
    <UploadCurriculumGuideInline v-if="!profileStore.hasCurriculum" />
    <app-guide-inline
        v-else-if="userJobStore.hasNonActionedInferredUserJobs"
        title="How do these jobs look?"
        text="We have inferred some of your roles based on the curriculum you uploaded. Please review them."
        @close="showDummyGuide = false"
        @submit="showDummyGuide = false"
    >
        <template #actions="{ close }">
            <v-spacer />
            <app-button @click="close" color="primary" border="secondary" variant="flat">
                Close
            </app-button>
        </template>
    </app-guide-inline>
</template>

<script setup lang="ts">
    import { useUserJobStore } from '@/store/user-job/store';
    import { ref } from 'vue';
    import UploadCurriculumGuideInline from '@/components/canvas/learned-experiences/UploadCurriculumGuideInline.vue';
    import { useUserProfileStore } from '@/store/profile/store';

    const userJobStore = useUserJobStore();
    const profileStore = useUserProfileStore();

    const showDummyGuide = ref(true);
</script>
