    <template>
    <v-container
        fluid
        class="fill-height app-main-layout__scrollable"
        :class="cssClassWrapperScrollable"
    >
        <v-container fluid class="fill-height app-main-layout__scrollable__content">
            <AppRouterView />
        </v-container>
    </v-container>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import AppRouterView from '@/AppRouterView.vue';

    const { responsiveClass } = useIsDesktop();

    const cssClassWrapperScrollable = computed(() => {
        return {
            'tutorial-page': useNavigationStore().isTutorial,
            [responsiveClass.value]: true,
        };
    });
</script>

<style scoped lang="scss">
    .app-main-layout__scrollable.desktop {
        padding: 60px 40px 54px;

        &.tutorial-page {
            padding-bottom: 0;
            border-radius: 8px;
            background: var(--v-background-linear-gradient-light);
        }

        overflow-y: scroll;
        /* Hide scrollbar */
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For IE and Edge */

        .app-main-layout__scrollable__content {
            padding: 0;
            max-width: 1382px;
        }
    }

    .app-main-layout__scrollable.mobile {
        padding: 0;

        &.tutorial-page {
            background: var(--v-background-linear-gradient-light);
        }

        &.activity-layout {
            &.tutorial-page {
                background: var(--v-background-linear-gradient-light);
            }
        }

        .app-main-layout__scrollable__content {
            padding: 0 16px;
        }
    }
</style>
