<template>
    <app-guide-avatar v-if="isAssistantMessage" class="ml-0 mr-3 mt-1 pa-0" size="58" />
    <v-avatar v-if="isCurrentUserMessage" color="custom-green-light" class="ml-0 ma-2 pa-3">
        <span class="text-h7">You</span>
    </v-avatar>
    <v-avatar v-if="isAdminUserMessage" color="custom-yellow" class="ml-0 ma-2 pa-3">
        <span class="text-h7">Admin</span>
    </v-avatar>
    <!--    <v-container class="pa-0" v-else-if="isUserMessage">-->
    <!--        <v-row justify="end" no-gutters>-->
    <!--            <v-avatar color="custom-purple-mid" size="x-small" class="ml-n2 pa-3">-->
    <!--                {{ displayUserInitials }}-->
    <!--            </v-avatar>-->
    <!--        </v-row>-->
    <!--    </v-container>-->
</template>

<script setup lang="ts">
    import { ASSISTANT, type GuideMessage } from '@/store/guide/types';
    import { computed } from 'vue';
    import type { User } from '@/api/types/user';
    import { useAuth0 } from '@/auth/auth';

    const props = defineProps<{
        message: GuideMessage;
        user: User;
    }>();

    const { user: authUser } = useAuth0();

    const isCurrentUserMessage = computed(() => {
        return props.message.senderId === props.user.id.toString();
    });

    const isAssistantMessage = computed(() => {
        return props.message.senderId === ASSISTANT.id;
    });

    const isAdminUserMessage = computed(() => {
        return (
            props.message.senderId !== ASSISTANT.id &&
            props.message.senderId !== props.user.id.toString()
        );
    });
</script>
