<template>
    <v-container class="welcome-item-card" :class="type">
        <div class="welcome-item-card__icon" :class="type">
            <app-fa-icon :icon="icon" />
        </div>
        <div class="welcome-item-card__content" :class="responsiveClass">
            <div class="text-heading-h4 align-self-stretch text-center text-custom-neutral-black">
                {{ title }}
            </div>
            <div class="text-center text-paragraph-md text-custom-neutral-900 px-0">
                {{ description }}
            </div>
        </div>
    </v-container>
</template>
<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const { responsiveClass } = useIsDesktop();

    defineProps<{
        type: 'why-card' | 'guide-card';
        icon: string;
        title: string;
        description: string;
    }>();
</script>

<style lang="scss" scoped>
    .welcome-item-card {
        display: flex;
        padding: 32px 20px;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        flex: 1 0 0;
        align-self: stretch;

        border-radius: 8px;
        background: var(--Neutral-White, #fff);

        &.guide-card {
            border: 2px solid #002e7d;
        }

        &.why-card {
            border: 2px solid rgb(var(--v-theme-story-outline));
        }
    }

    .welcome-item-card__icon {
        font-size: 36px;
        font-weight: 400;
        line-height: 57.6px;
        word-wrap: break-word;

        &.guide-card {
            color: rgb(var(--v-theme-secondary-blue));
        }

        &.why-card {
            color: rgb(var(--v-theme-custom-neutral-black));
        }
    }

    .welcome-item-card__content {
        align-self: stretch;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
        display: flex;

        &.mobile.sm {
            height: fit-content;
        }

        &.mobile.xs {
            height: fit-content;
        }
    }
</style>
