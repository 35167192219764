<template>
    <v-row
        class="mt-8 text-paragraph-md font-weight-700 w-100 text-center"
        justify="center"
        no-gutters
    >
        <div class="text-heading-h5 text-uppercase font-weight-bold">
            {{ props.value.title }}
        </div>
        <div class="pa-4">
            {{ props.value.description }}
        </div>
    </v-row>

    <CareerPlanStageSuggestion
        v-if="showSuggestion"
        :value="props.value"
        @on-remove="onRemoveSuggestion"
        @on-add-new="onAddSuggestion"
    />

    <PlanActionList
        :item="props.value"
        class="mt-6 px-4"
        @on-add-new="onAddNew"
        @edit-save-click="onEditSaveClick"
        @remove-click="onRemoveClick"
    />
</template>

<script setup lang="ts">
    import { useCareerPlanStore } from '@/store/plan/career/store';
    import PlanActionList from '@/components/plan/common/PlanActionList.vue';
    import type {
        CareerPlanStages,
        EditableFutureAspirationAction,
        FutureAspirationActionEditFormItem,
    } from '@/store/plan/career/types';
    import { useAuth0 } from '@/auth/auth';
    import CareerPlanStageSuggestion from '@/components/plan/career-plan/CareerPlanStageSuggestion.vue';
    import type { EditablePlanAction } from '@/store/plan/common/types';
    import { computed, ref } from 'vue';

    const props = defineProps<{
        value: CareerPlanStages;
    }>();

    const store = useCareerPlanStore();

    const { getAccessTokenSilently } = useAuth0();

    const show = ref(true);

    /**
     * The suggestion is only visible when there are no actions for
     * that stage and the user has not interacted with the suggestion yet
     */
    const showSuggestion = computed(() => {
        return !props.value.actions.length && show.value;
    });

    const onRemoveSuggestion = (item: EditablePlanAction) => {
        show.value = false;
    };

    const onAddSuggestion = (item: EditableFutureAspirationAction) => {
        show.value = false;
        store.addAction(item);
    };

    const onAddNew = (item: CareerPlanStages) => {
        store.addNewStageAction(item);
    };

    const onEditSaveClick = async (
        item: EditableFutureAspirationAction,
        actionForm: FutureAspirationActionEditFormItem,
    ) => {
        const accessToken = await getAccessTokenSilently();
        await store.saveAction(item, actionForm, accessToken);
    };

    const onRemoveClick = async (action: EditableFutureAspirationAction) => {
        const accessToken = await getAccessTokenSilently();
        await store.removeAction(action, accessToken);
    };
</script>
