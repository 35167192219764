<template>
    <HomeAbout
        title="About Story"
        :description="aboutDescription"
        :image="aboutSrc"
        :process-cards="processCards"
        :show-submit="false"
    />
</template>

<script setup lang="ts">
    import aboutSrc from '@/assets/images/story/about.svg';
    import HomeAbout from '@/components/common/home/HomeAbout.vue';

    const aboutDescription = 'Story is your personal record of your Career Achievements and Professional Value.';

    const processCards = [
        {
            order: 1,
            title: 'Clarity on your character & achievements',
            description:
                'We offer activities to help you get clear on your unique style and character, and a way to record and understand the value of your achievements.',
        },
        {
            order: 2,
            title: 'Discover your superpower',
            description:
                'Everyone has a unique talent. We show you yours. We use the information provided to craft powerful summary statements on what makes you uniquely valuable to the workforce.',
        },
        {
            order: 3,
            title: 'Activate your new insights',
            description:
                'Use the insights from your story such as your Growth Values, to focus on new behaviours and talents you have to accelerate your ambition and open up new growth.',
        },
    ];
</script>
