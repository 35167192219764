<template>
    <div class="text-heading-h3">Now, we’d love to know a little about your career so far</div>
    <div class="text-paragraph-md my-5">
        Uploading your CV allows us to help you significantly better on your journey.
        If you cannot do it now, please make sure to do it later via your profile page.
    </div>
    <div class="w-100 mt-8">
        <v-text-field
            label="Current Company"
            variant="outlined"
            v-model="userInputs.current_company"
            :rules="[requiredRule('Current Company')]"
            validate-on="input"
        />

        <v-text-field
            label="Job Title"
            variant="outlined"
            v-model="userInputs.job_title"
            :rules="[requiredRule('Job Title')]"
            validate-on="input"
        />

        <v-text-field
            prepend-inner-icon="mdi-linkedin"
            label="LinkedIn Profile URL ... coming soon"
            variant="outlined"
            v-model="userInputs.linkedin_profile"
            disabled
        />

        <CurriculumVitaeInput :show-extended-hint="true" />
    </div>

    <div class="text-heading-h4 mt-4 mb-2">Career confidence rating</div>
    <div class="text-paragraph-md mb-5">
        Thinking about your current role, how confident are you in it today?
    </div>
    <div class="w-100">
        <v-rating
            v-model="userInputs.current_company_confidence_rating"
            :max="5"
            :step="0.5"
            :readonly="false"
            half-increments
            hover
            :rules="[requiredRule('Current Company Career Confidence')]"
            :hide-details="false"
            validate-on="input"
            size="x-large"
            color="primary"
            label="Current Company Career Confidence"
            class="border-1-custom-neutral-grey-400 rounded-4"
        />
    </div>
</template>

<script setup lang="ts">
    import CurriculumVitaeInput from '@/components/profile/curriculum/CurriculumVitaeInput.vue';
    import { requiredRule } from '@/components/profile/rules';
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);
</script>
