<template>
    <app-page-content>
        <template #intro>
            <app-page-content-intro>
                <template #title>
                    <div class="text-heading-h1 font-weight-regular">
                        How are you going with
                        <span class="font-weight-700">{{
                            store.challenge?.description.toLowerCase()
                        }}</span
                        >?
                    </div>
                </template>

                <SingleChallengeProgressCard />

                <SingleChallengeUpdateProgressViewOptions />
            </app-page-content-intro>
        </template>

        <v-container
            class="pa-0 d-flex challenge-sequencing-container"
            v-for="(action, index) in store.actions"
            :key="`${index}`"
        >
            <app-sorting-controls
                :position="index + 1"
                :total="store.actions.length"
                :hide-sort-buttons="true"
                line-color="grey"
            />
            <SingleChallengeActionListItem
                :key="index"
                :item="action"
                class="mb-2 w-100"
                :is-editable="false"
                @mark-as-completed="onMarkAsCompletedClick(action)"
            />
        </v-container>

        <app-guide-chat-activator />
        <app-guide-chat-dialog :screen="GuideSupportedScreen.ChallengePath" />
    </app-page-content>

    <SingleChallengeEditActionListItemFeedback
        v-model="showFeedbackDialog"
        @feedback-save="onFeedbackSave"
    />

    <AppGuideModal
        v-model="showSuccess"
        title="Wow you really showed that challenge who’s boss!"
        action-text="Continue"
        @submit="onContinueAfterComplete"
    >
        <template #default>
            Give yourself a pat on the back for overcoming this challenge. Now is a great to to
            reflect on the experience.
        </template>
    </AppGuideModal>

    <PageActivity :area="UserActivityArea.ChallengePath" />
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    import { useCanvasStore } from '@/store/canvas/store';

    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { useActivityStore } from '@/store/activity/store';
    import { useSingleChallengePathStore } from '@/store/challenge-path/singleChallengeStore';
    import { verify } from '@/store/verify';
    import SingleChallengeActionListItem from '@/components/pathway/single-challenge/edit/SingleChallengeEditActionListItem.vue';
    import AppSortingControls from '@/components/common/AppSortingControls.vue';
    import SingleChallengeEditActionListItemFeedback from '@/components/pathway/single-challenge/edit/SingleChallengeEditActionListItemFeedback.vue';
    import type { CurrentChallengeAction } from '@/api/types/plan/currentChallengeAction';
    import SingleChallengeProgressCard from '@/components/pathway/single-challenge/edit/SingleChallengeProgressCard.vue';
    import SingleChallengeUpdateProgressViewOptions from '@/views/pathways/SingleChallengeUpdateProgressViewOptions.vue';
    import { GuideSupportedScreen } from '@/store/guide/types';
    import { isNew } from '@/store/challenge-path/util';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import { useChallengePathStore } from '@/store/challenge-path/challengePathStore';

    const challengePathStore = useChallengePathStore();

    const navigationStore = useNavigationStore();

    const store = useSingleChallengePathStore();

    const challenge = navigationStore.ctx.item;
    const challengeId = Number(verify(challenge.id, 'No challenge id'));

    console.log(`Loading single challenge ${challengeId}`);

    if (isNew(challenge)) {
        throw new Error('New challenge should not be loaded here');
    } else {
        await store.loadEdit(challengeId);
    }

    const showFeedbackDialog = ref(false);
    const showSuccess = ref(false);

    const onContinueAfterComplete = async () => {
        await challengePathStore.load();
        await navigationStore.setTabByKey('select-challenge');
    };

    const onMarkAsCompletedClick = (item: CurrentChallengeAction) => {
        store.onMarkAsCompleted(item);

        showFeedbackDialog.value = true;
    };

    const onFeedbackSave = () => {
        if (store.isCompleted) {
            showSuccess.value = true;
        }
    };

    const onMarkChallengeComplete = async () => {};
</script>
