<template>
    <teleport defer to="#guide-teleport-container-avatar">
        <v-card
            variant="outlined"
            class="app-guide-chat-activator-card pa-0 overflow-visible align-center cursor-pointer bg-white"
            :class="responsiveClass"
            :height="isDesktop ? 50 : 46"
            @click="onShowDialog"
        >
            <v-card-item class="py-1 px-1 text-paragraph-md">
                <template #prepend>
                    <v-badge
                        class="pl-0"
                        v-if="store.isClosedWithSuggestions"
                        color="custom-grey-400"
                        :inline="true"
                        :floating="true"
                    >
                        <template #badge>
                            <span class="badge-closed-with-suggestions" />
                        </template>
                        <app-guide-avatar :size="isDesktop ? 40 : 36" :animate="true" />
                    </v-badge>
                    <app-guide-avatar v-else-if="model" :size="isDesktop ? 40 : 36" />
                </template>
                <div
                    class="text-paragraph-md pl-2 pr-4"
                    :class="
                        isDesktop ? 'text-custom-neutral-grey-600' : 'text-custom-neutral-grey-900'
                    "
                >
                    Can I help?
                </div>
            </v-card-item>
        </v-card>
    </teleport>
</template>

<script setup lang="ts">
    import { ref } from 'vue';

    import { useGuideStore } from '@/store/guide/store';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const model = ref(true);

    const store = useGuideStore();

    const { isDesktop, responsiveClass } = useIsDesktop();

    const onShowDialog = (value: boolean) => {
        store.showDialog();
    };
</script>

<style scoped lang="scss">
    .app-guide-chat-activator-card {

        width: max-content;
        border-radius: 100px;
        border: 1px solid rgb(var(--v-theme-guide-outline));

        z-index: 1020;
        &.mobile {
            background: rgba(255, 255, 255, 0.4);
        }
    }

    :deep(.v-badge) {
        display: inline-flex !important;
    }

    :deep(.v-badge__badge) {
        min-width: 30px;
        min-height: 30px;
        border-radius: 20px;
        position: relative;
        margin-top: -48px;
        margin-left: -13px;
    }

    .badge-closed-with-suggestions {
        color: white;
    }

    .badge-closed-with-suggestions:after {
        content: '';
        animation: spin 3s linear infinite;
    }
</style>
