<template>
<!--    <StorySectionActivityInlineList label="Technical" :items="technicalSkills" />-->
<!--    <StorySectionActivityInlineList label="Learned" :items="learnedSkills" />-->
    <StorySectionActivityInlineList label="Technical" :items="technicalSkills" class="mb-2" />
    <StorySectionActivityInlineList  label="Learned" :items="learnedSkills" />
</template>

<script setup lang="ts">
    import { useStoryStore } from '@/store/story/store';
    import { computed } from 'vue';
    import StorySectionActivityInlineList from '@/components/story/section/activity/StorySectionActivityInlineList.vue';
    import { capitalize } from 'lodash';
    import type { StoryActivity } from '@/store/story/types';

    const props = defineProps<{
        item: StoryActivity;
    }>();

    const store = useStoryStore();

    const technicalSkills = computed(() => {
        return store.technicalSkills.map((item) => capitalize(item.description));
    });

    const learnedSkills = computed(() => {
        return store.learnedSkills.map((item) => capitalize(item.description));
    });
</script>
