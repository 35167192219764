<template>
    <app-button
        :height="responsiveHeight"
        :max-width="maxWidth"
        :size="size"
        :prepend-icon="icon"
        @click="emit('click')"
        :variant="variant"
        :disabled="disabled"
        :color="color"
        :class="responsiveClass"
    />
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    const props = withDefaults(
        defineProps<{
            size?: 'x-small' | 'small' | 'default' | 'large' | 'x-large';
            icon: string;
            color?: string;
            variant?: 'outlined' | 'flat' | 'text' | 'elevated' | 'tonal' | 'plain';
            disabled?: boolean;
        }>(),
        { size: 'x-small', color: 'primary', variant: 'flat', disabled: false },
    );

    const { isDesktop, isMobile, responsiveClass } = useIsDesktop();

    // The icon is a square, so we can use the height as the width
    const maxWidth = computed(() => {
        return responsiveHeight.value;
    });

    const responsiveHeight = computed(() => {
        if (isDesktop.value) {
            return 38;
        }

        if (isMobile.value) {
            return 30;
        }
    });

    const emit = defineEmits(['click']);
</script>

<style scoped lang="scss">
    .desktop {
        :deep(.v-btn__prepend) {
            font-size: 16px !important;
            margin-right: -2px;
        }
    }

    .mobile {
        button.v-btn {
            min-width: 30px !important;
            width: 30px !important;
            padding: 0 !important;
        }

        :deep(.v-btn__prepend) {
            font-size: 12px !important;
            margin-right: -2px;
        }
    }

    button.v-btn--variant-outlined.text-white {
        border: 1px solid white;
    }
</style>
