<template>
    <app-tutorial section-name="Challenge Paths" action-text="Start" :steps="4">
        <template #title="{ step }">
            <span v-if="step === 1">Let’s face your challenge!</span>
            <span v-else-if="step === 2">Plan of attack</span>
            <span v-else-if="step === 3">Let me guide you</span>
            <span v-else-if="step === 4">I want you to succeed!</span>
        </template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    Let me help you solve some of your everyday challenges and make your work-life
                    easier.
                </p>
            </div>
            <div v-else-if="step === 2">
                <p>
                    Having an action plan of activities will help you to face your challenges and
                    learn new skills.
                </p>
            </div>
            <div v-else-if="step === 3">
                <p>
                    I’ll help you to select a set of activities that you feel comfortable with, and
                    then come up with a timeline to work through them
                </p>
            </div>
            <div v-else-if="step === 4">
                <p>
                    To make sure you stay on track and feel supported I’ll check in on you
                    throughout.
                </p>
                <p>
                    Remember you can always come back and change your activities or adjust your
                    timeframe.
                </p>
            </div>
        </template>
    </app-tutorial>
</template>
