<template>
    <v-container class="fill-height">
        <v-row class="text-center">
            <v-col cols="12" class="text-center text-heading-h6 loader-class" :css-class="cssClass">{{
                title ?? 'Loading'
            }}</v-col>
            <v-col
                cols="12"
                v-if="subtitle"
                class="text-center text-subtitle-1 mb-4"
                :css-class="cssClass"
                >{{ subtitle }}</v-col
            >
            <v-col cols="4"></v-col>
            <v-col cols="4" class="justify-center">
                <v-progress-linear
                    size="60"
                    width="6"
                    :color="color"
                    class="text-center"
                    :indeterminate="true"
                />
            </v-col>
            <v-col cols="4"></v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    const props = withDefaults(
        defineProps<{
            title?: string | null;
            subtitle?: string | null;
            cssClass?: string;
            color?: string;
        }>(),
        {
            color: 'secondary',
            cssClass: 'text-custom-green-mid',
            title: 'Loading',
        },
    );

    // Reload the page if the loader is still present after 20 seconds
    let timer = 0;
    const intervalId = window.setInterval(() => {
        timer += 1;
        const loaderObjects = document.getElementsByClassName("loader-class");
        if (loaderObjects.length > 0) {
            // Only do this for full-page loaders. For them the textContent starts with "Loading".
            if (loaderObjects[0].textContent?.startsWith("Loading")) {
                console.log("Page loader is still present after " + timer + " seconds");
                if (timer > 20) {
                    console.log("Reloading the page");
                    location.reload();
                }
            }
        }
        else {
            clearInterval(intervalId);
        }

    }, 1000);

</script>
