<template>
    <v-container class="pa-0">
        <v-row
            justify="center"
            align="center"
            no-gutters
            class="pa-4"
            :class="props.item.actions.length ? '' : 'border-dashed-custom-neutral-grey-400'"
        >
            <app-plus-button
                class="text-paragraph-md"
                @click="emit('on-add-new', props.item)"
                height="36"
            >
                Add an action
            </app-plus-button>
        </v-row>

        <v-row no-gutters class="pa-4 bg-white" v-if="props.item.actions.length">
            <PlanActionListItem
                class="w-100 pa-0"
                v-for="(action, index) in actionsByDueDate"
                :item="action"
                :index="index"
                @edit-click="onEditClick"
                @edit-cancel-click="onEditCancelClick"
                @edit-save-click="(...args) => emit('edit-save-click', ...args)"
                @remove-click="(...args) => emit('remove-click', ...args)"
            />
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import PlanActionListItem from '@/components/plan/common/PlanActionListItem.vue';

    import { type EditableCurrentChallenge } from '@/store/plan/current/types';
    import { type EditablePlanAction, PlanActionItemState } from '@/store/plan/common/types';
    import type { CareerPlanStages } from '@/store/plan/career/types';
    import { byDueDateComparator, setEditableActionState } from '@/store/plan/common/utils';
    import { computed } from 'vue';

    const props = defineProps<{
        item: EditableCurrentChallenge | CareerPlanStages;
    }>();

    const emit = defineEmits(['on-add-new', 'edit-save-click', 'remove-click']);

    const onEditClick = (item: EditablePlanAction) => {
        setEditableActionState(item, PlanActionItemState.Edit);
    };

    const onEditCancelClick = (item: EditablePlanAction) => {
        setEditableActionState(item, PlanActionItemState.Saved);
    };

    const actionsByDueDate = computed(() => {
        return props.item.actions.sort(byDueDateComparator);
    });
</script>
