<template>
    <app-page-tabs>
        <app-page-header title="Future aspiration" />
        <FutureAspirationsGuide v-if="store.isLoaded" />

        <template #tutorial>
            <FutureAspirationGuideModalExplanation @done="navigationStore.setNext" />
        </template>
        <template #activity>
            <app-page-content>
                <template v-slot:intro>
                    <app-page-content-intro title="Visualise your ideal future">
                        We want to help you progress on your path. To do so, it would be useful for
                        us to understand your goal. Think ahead to the next 5 to 10 years. What is
                        your career ambition?
                    </app-page-content-intro>
                </template>
                <FutureAspirationsForm v-if="!store.value" />
                <FutureAspirationsContent v-else :value="store.value" />
            </app-page-content>
        </template>

        <SubmitNotification :store="store" :activity-area="UserActivityArea.FutureAspiration" />

        <WarningChangesWillBeLost
            :value="store.isDirty"
            @on-discard-changes="onDiscardChanges"
            @on-submit="onSaveClick"
        />

        <app-page-tabs-footer />

        <PageActivity :area="UserActivityArea.FutureAspiration" />
    </app-page-tabs>
</template>

<script setup lang="ts">
    import { onBeforeUnmount, onMounted } from 'vue';
    import { useCanvasStore } from '@/store/canvas/store';
    import FutureAspirationsForm from '@/components/canvas/future-aspirations/FutureAspirationsForm.vue';
    import FutureAspirationsContent from '@/components/canvas/future-aspirations/FutureAspirationsContent.vue';
    import SubmitNotification from '@/components/footer-actions/SubmitNotification.vue';
    import { useFutureAspirationsStore } from '@/store/future-aspirations/store';
    import FutureAspirationsGuide from '@/components/canvas/future-aspirations/FutureAspirationsGuide.vue';
    import WarningChangesWillBeLost from '@/components/footer-actions/WarningChangesWillBeLost.vue';
    import { UserActivityArea } from '@/api/types/userActivity';
    import PageActivity from '@/components/common/PageActivity.vue';
    import { storyRoute } from '@/router/routes';
    import { useRouter } from 'vue-router';
    import { useActivityStore } from '@/store/activity/store';
    import { useNavigationStore } from '@/store/navigation/useNavigationStore';
    import FutureAspirationGuideModalExplanation from '@/components/canvas/future-aspirations/FutureAspirationGuideModalExplanation.vue';
    import { makeActivityTabs } from '@/store/navigation/utils';

    const router = useRouter();
    const activityStore = useActivityStore();
    const canvasStore = useCanvasStore();
    const store = useFutureAspirationsStore();
    const navigationStore = useNavigationStore();

    const items = makeActivityTabs({
        completed: () => store.isAreaComplete,
        footer: {
            prev: {
                // TODO: change to growth path when available
                to: storyRoute,
            },
            next: {
                cb: async () => await onNextCb(),
                loading: () => store.isUpdating,
                disabled: () => !store.isDirty,

                // TODO: change to growth path when available
                to: storyRoute,
            },
        },
    });
    await navigationStore.init(items);

    await canvasStore.load();
    await store.load();

    onMounted(async () => {
        await activityStore.load();
        await activityStore.addEntryActivity(UserActivityArea.FutureAspiration);
    });

    const onDiscardChanges = () => {
        // This is done to reset the store, so changes are discarded, so next time the data is loaded from scratch
        // This is only done on stores that are not save on change, and have an explicit save button
        store.$reset();
    };

    const onSaveClick = async () => {
        await store.saveProgress();

        if (!store.isError) {
            await router.push(storyRoute);
        }
    };

    const onNextCb = async (): Promise<boolean> => {
        await store.saveProgress();

        if (store.isError) {
            return false;
        }

        return true;
    };

    onBeforeUnmount(async () => {
        await activityStore.addExitActivity(UserActivityArea.FutureAspiration);
    });
</script>
