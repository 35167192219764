<template>
    <v-card
        color="custom-neutral-grey-100"
        min-height="200"
        min-width="240"
        class="w-100 mx-6 pa-10 pb-4 rounded-lg box-shadow-grey d-flex flex-column"
    >
        <v-card-item class="pa-0" :style="{ height: '80px' }">
            <span class="text-heading-h4 text-use-heading-font fill-height"> {{ props.value.title }} </span>
            <template v-slot:append>
                <app-guide-avatar size="45" />
            </template>
        </v-card-item>
        <v-spacer />
        <v-card-text class="py-4 pa-0">
            <v-icon color="custom-purple-mid" size="x-large">mdi-calendar-blank-outline</v-icon>
            Due date
        </v-card-text>
        <v-card-actions class="pa-0">
            <v-spacer />
            <app-button
                class="text-paragraph-md"
                @click="emit('on-remove', props.value)"
                height="36"
            >
                Remove
            </app-button>
            <app-plus-button
                class="text-paragraph-md"
                @click="emit('on-add-new', props.value)"
                height="36"
            >
                Add to my list
            </app-plus-button>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
    import type { EditablePlanAction } from '@/store/plan/common/types';

    const props = defineProps<{
        value: EditablePlanAction;
    }>();

    const emit = defineEmits(['on-add-new', 'on-remove']);
</script>

<style scoped lang="scss">
    // To help align title of the suggestions to the top,
    // so in each different stage columns the titles are aligned
    :deep(.v-card-item__content),
    :deep(.v-card-item__append) {
        height: 100%;
    }
</style>
