<template>
    <teleport defer to="#guide-teleport-container-box">
        <div class="w-100 mx-0 mb-2 mr-4 pa-0 d-flex justify-end align-end text-left" v-if="show">
            <svg
                v-if="isDesktop"
                class="app-guide-inline-card-triangle"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
            >
                <path
                    d="M20 2.82843C20 1.04662 17.8457 0.154284 16.5858 1.41421L1.41422 16.5858C0.154287 17.8457 1.04662 20 2.82843 20H18C19.1046 20 20 19.1046 20 18V2.82843Z"
                    fill="#6D0EDA"
                />
            </svg>
            <svg
                v-else
                class="app-guide-inline-card-rectangle"
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="14"
                viewBox="0 0 19 14"
                fill="none"
            >
                <path
                    d="M11.0651 1.23589C10.2899 0.128383 8.66197 0.0930849 7.83944 1.16595L0.466263 10.7831C-0.542429 12.0988 0.395618 14 2.05347 14H16.1587C17.7767 14 18.725 12.1786 17.7972 10.8531L11.0651 1.23589Z"
                    fill="#4A18BC"
                />
            </svg>
            <v-card
                class="cursor-pointer mx-0 pa-5 app-guide-inline-card"
                :class="isDesktop ? 'w-100' : 'w-100 ml-5'"
                variant="flat"
            >
                <v-card-item
                    class="pa-0 mb-2"
                    v-if="closable"
                    :class="title || hasSlot('title') ? 'has-title' : 'no-title'"
                >
                    <div
                        v-if="title || hasSlot('title')"
                        class="text-wrap px-0 pt-0 mb-0 text-white text-heading-h4"
                    >
                        <span v-if="title">{{ title }}</span>
                        <slot v-else name="title" v-if="hasSlot('title')"></slot>
                    </div>
                    <template v-slot:append>
                        <v-icon
                            @click="close"
                            color="white"
                            size="16"
                            class="app-guide-inline-card-icon-close"
                        >
                            mdi-close
                        </v-icon>
                    </template>
                </v-card-item>
                <v-card-text class="pa-0">
                    <div class="text-paragraph-md text-white">
                        <div
                            class="pa-0 pr-4 text-paragraph-md text-word-phrase fill-height"
                        >
                            <span v-if="text">{{ text }}</span>
                            <slot v-else name="default" :close="close" />

                            <v-row
                                v-if="actionText"
                                no-gutters
                                class="mt-5 w-100 justify-start text-right"
                            >
                                <app-button
                                    @click="emit('submit')"
                                    :to="actionTo"
                                    class="text-paragraph-sm"
                                    color="primary"
                                    border="secondary"
                                    variant="flat"
                                >
                                    {{ actionText }}
                                </app-button>
                            </v-row>
                            <v-row v-else-if="hasSlot('actions')" no-gutters class="mt-4 w-100">
                                <slot name="actions" :close="close" />
                            </v-row>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </div>
    </teleport>
</template>

<script setup lang="ts">
    import { ref, useSlots } from 'vue';
    import { useGuideStore } from '@/store/guide/store';
    import type { DeclaredRoute } from '@/router/routes';
    import { useIsDesktop } from '@/composables/useIsDesktop';

    /**
     * This is a component that seems we are going to be using a lot in the app, so it is better to do an effor to have a flexible interface
     *
     *  - It allows title and text as props (usually when only text is passed)
     *  - It allows title and #default and #actions slots for a more deeper modification
     */
    const props = withDefaults(
        defineProps<{
            title?: string;
            text?: string;
            closable?: boolean;

            actionText?: string;
            actionTo?: DeclaredRoute;
        }>(),
        { closable: true },
    );

    const emit = defineEmits(['close', 'submit']);

    const { isDesktop } = useIsDesktop();
    const guideStore = useGuideStore();
    const show = ref(true);

    const close = () => {
        show.value = false;
        emit('close');
    };

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    .app-guide-inline-card-rectangle {
        position: absolute;
        left: 50%;
        top: 12px;
    }

    .app-guide-inline-card-triangle {
        position: absolute;
        right: 154px;
        top: 6px;
    }

    .app-guide-inline-card {
        border-radius: 12px;
        background: var(--secondary-guide-gradient, linear-gradient(93deg, #002e7d 0%, #90f 100%));
        box-shadow: 0px 4px 8px 2px rgba(38, 38, 38, 0.08);

        .app-guide-inline-card-icon-close {
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }

    .avatar-inline-guide {
        margin-bottom: -35px !important;
        z-index: 999;
    }

    .v-card-item.no-title {
        position: absolute;
        right: 10px;
        top: 10px;
    }
</style>
