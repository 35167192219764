import { computed, ref } from 'vue';
import type { NavigationTabItem } from '@/components/common/app-page/types';
import { defineStore } from 'pinia';
import { verify } from '@/store/verify';
import { useURLStore } from '@/store/navigation/useURLStore';

export const useNavigationStore = defineStore('navigation-store', () => {
    const items = ref<NavigationTabItem[]>([]);
    const item = ref<NavigationTabItem | null>(null);
    const ctx = ref<any | null>(null);

    const clearContext = async () => {
        ctx.value = null;
    };

    const setContext = (context?: any) => {
        ctx.value = context;
    };

    const init = async (newItems: NavigationTabItem[]): Promise<void> => {
        items.value = newItems;

        const selected = newItems.find((i) => i.default);
        await setCurrentTab(selected || newItems[0] || null);
    };

    const setCurrentTab = async (newItem: NavigationTabItem | null): Promise<void> => {
        item.value = newItem;

        if (item.value?.onSelect) {
            await item.value?.onSelect();
        }

        if (item.value?.key) {
            const queryParams = { tab: item.value.key };

            await useURLStore().updateQueryParams(queryParams);
        }
    };

    const setTabByKey = async (name: string, context?: any): Promise<void> => {
        const found = items.value.find((i) => i.key === name);

        if (context) {
            ctx.value = context;
        }

        await setCurrentTab(verify(found, 'Item not found'));
    };

    const setPrevious = async () => {
        const current = verify(item.value, 'Current tab must be set');
        const previousTab = items.value.find((i) => {
            return i.order === current.order - 1;
        });
        await setCurrentTab(verify(previousTab, 'Previous item not found'));
    };

    const setNext = async () => {
        const current = verify(item.value, 'Current tab must be set');
        const nextTab = items.value.find((i) => {
            return i.order === current.order + 1;
        });
        await setCurrentTab(verify(nextTab, 'Next item not found'));
    };

    const setTutorial = async (): Promise<void> => {
        const tutorialItem = items.value.find((i) => i.key === 'tutorial' || i.key === 't');
        await setCurrentTab(verify(tutorialItem, 'Tutorial item not found'));
    };

    const isCurrent = (key: string): boolean => {
        return item.value?.key === key;
    };

    const isTutorial = computed(() => {
        return isCurrent('tutorial') || isCurrent('t');
    });

    return {
        items,
        item,
        ctx,
        clearContext,
        setContext,
        init,

        isTutorial,
        isCurrent,

        setCurrent: setCurrentTab,
        setTutorial,
        setTabByKey,
        setPrevious,
        setNext,
    };
});
