<template>
    <!--
        At the moment we use story section (InlineGuideSection.Story) as the text from the backend are compatible with this activity
        They talk about uploading CV, jobs, etc, all related to career history which is part of this activity
        If a more grained messaging is needed, consider adding a new inline guide in the api
     -->
    <ContextualInlineGuide
        :only="[InlineGuideActionName.UploadCv, InlineGuideActionName.ReTryUploadCv]"
        :section="InlineGuideSection.Story"
    />

    <app-guide-chat-dialog :screen="GuideSupportedScreen.LearnedExperiences" />
</template>

<script setup lang="ts">
    import { GuideSupportedScreen } from '@/store/guide/types';
    import { InlineGuideActionName, InlineGuideSection } from '@/store/inline-guide/types';
    import ContextualInlineGuide from '@/components/inline-guide/ContextualInlineGuide.vue';
    import { useInlineGuideStore } from '@/store/inline-guide/store';

    const inlineGuideStore = useInlineGuideStore();
</script>
