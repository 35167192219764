<template>
    <v-app-bar
        class="app-page-navigation"
        :class="responsiveClass"
        flat
        :floating="true"
        height="64"
    >
        <div
            class="app-page-navigation-container d-flex align-center pa-0 w-100"
            :class="responsiveClass"
        >
            <span v-if="title ?? sectionTitle" class="text-paragraph-md pl-8 pr-8 align-center">
                {{ title ?? sectionTitle }}
            </span>
            <span v-else-if="hasSlot('title')" class="text-paragraph-md pl-8 pr-8 align-center">
                <slot name="title" />
            </span>

            <v-divider color="outline" thickness="1" vertical class="opacity-1"></v-divider>

            <div class="d-flex justify-center align-center app-page-header-desktop-navigation">
                <slot name="navigation" />
            </div>

            <div v-if="!hideTutorial" class="app-page-header-desktop-navigation-guide">
                <app-page-guide-locator />
                <app-page-guide-message-container />
            </div>
        </div>
    </v-app-bar>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import type { NavigationTabItem } from '@/components/common/app-page/types';
    import AppPageGuideLocator from '@/components/common/app-page/AppPageGuideLocator.vue';
    import AppPageGuideMessageContainer from '@/components/common/app-page/AppPageGuideMessageContainer.vue';
    import { useSlots } from 'vue';

    const emit = defineEmits(['show-tutorial']);

    withDefaults(
        defineProps<{
            title?: string;
            items: NavigationTabItem[];
            sectionTitle?: string;
            hideTutorial?: boolean;
        }>(),
        {
            title: '',
        },
    );

    const slots = useSlots();

    const hasSlot = (name: string) => {
        return !!slots[name];
    };

    const { responsiveClass } = useIsDesktop();
</script>

<style lang="scss" scoped>
    .app-page-navigation {
        // The background color of the <v-app-bar /> and the padding gives the impression that the
        // scrolling happens withing a the app frame (keeping a primary color background in the top and right)
        //background: rgb(var(--v-theme-primary));
        background: none;

        :deep(.v-toolbar__content) {
            padding-right: 4px;
            padding-top: 4px;
        }

        .app-page-header-desktop-navigation {
            left: 50%;
            transform: translateX(-50%);
            position: absolute;
            height: 60px;
        }

        .app-page-header-desktop-navigation-guide {
            position: absolute;
            right: 16px;
        }

        .app-page-navigation-container {
            border-radius: 8px 8px 0 0;
            background-color: white;
            height: 60px;
            border-bottom: 1px solid rgb(var(--v-theme-outline));
        }
    }
</style>
