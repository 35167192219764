<template>
    <v-sheet class="pa-2 empty-sheet w-100" :class="cssClass">
        <v-container class="empty-container" fluid :class="responsiveClass">
            <v-row class="w-100 pa-0" no-gutters variant="flat" :color="backgroundColor">
                <v-col :cols="isDesktop ? 3 : 12">
                    <img :src="image" class="w-50 h-100" alt="" />
                </v-col>
                <v-col :cols="isDesktop ? 6 : 12">
                    <div class="text-caption-subtitle w-100" :class="isDesktop ? 'mt-2' : 'mt-10'">
                        Next
                    </div>
                    <div class="text-heading-h1 w-100 mt-2 mb-4">
                        {{ title }}
                    </div>
                    <div class="text-paragraph-lg text-left w-100" v-if="description">
                        {{ description }}
                    </div>
                    <div
                        v-else-if="hasSlot('description')"
                        class="text-paragraph-lg text-left w-100"
                    >
                        <slot name="description" />
                    </div>
                </v-col>
                <v-col :cols="isDesktop ? 3 : 12" class="align-center">
                    <div
                        class="fill-height align-center d-flex next-icon"
                        :class="isDesktop ? 'justify-center' : ''"
                        @click="onClick"
                    >
                        <!-- TODO: note that the thin version of the icon is in the pro license -->
                        <!-- <app-fa-icon :icon="['fat', 'arrow-right']" />-->
                        <div class="next-card-icon-container" :class="responsiveClass">
                            <v-img
                                :src="arrowRightSVG"
                                :height="isDesktop ? 120 : 80"
                                :width="isDesktop ? 120 : 80"
                            />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</template>

<script setup lang="ts">
    import { useIsDesktop } from '@/composables/useIsDesktop';
    import { computed, useSlots } from 'vue';
    import { type RouteLocationRaw, useRouter } from 'vue-router';
    import arrowRightSVG from '@/assets/images/arrow-right.svg';

    const { responsiveClass, isDesktop } = useIsDesktop();

    const props = defineProps<{
        title: string;
        description?: string;
        image: string;
        color:
            | 'primary-light'
            | 'secondary-blue'
            | 'secondary-purple'
            | 'secondary-pink'
            | 'secondary-orange';
        /**
         * The route to navigate to when the card is clicked.
         * This prop is optional. If not provided, the card will not trigger navigation.
         */
        to?: RouteLocationRaw;
    }>();

    const cssClass = computed(() => {
        return {
            [responsiveClass.value]: true,
            [props.color]: true,
        };
    });

    const emit = defineEmits(['next-click']);

    const router = useRouter();

    const backgroundColor = computed(() => {
        switch (props.color) {
            case 'primary-light':
                return 'primary-light';
            case 'secondary-blue':
                return 'secondary-blue-light';
            case 'secondary-purple':
                return 'secondary-purple-light';
            case 'secondary-pink':
                return 'secondary-pink-light';
            case 'secondary-orange':
                return 'secondary-blue-light';
            default:
                throw new Error('Invalid color');
        }
    });

    const onClick = () => {
        emit('next-click');
        if (props.to) {
            router.push(props.to);
        }
    };

    const slots = useSlots();
    const hasSlot = (name: string) => {
        return !!slots[name];
    };
</script>

<style scoped lang="scss">
    .secondary-blue {
        --section-border-color: rgb(var(--v-theme-secondary-blue));
        --section-background: rgb(var(--v-theme-secondary-blue-light));
    }

    .secondary-purple {
        --section-border-color: rgb(var(--v-theme-secondary-purple));
        --section-background: rgb(var(--v-theme-secondary-purple-light));
    }

    .secondary-pink {
        --section-border-color: rgb(var(--v-theme-secondary-pink));
        --section-background: rgb(var(--v-theme-secondary-pink-light));
    }

    .secondary-orange {
        --section-border-color: rgb(var(--v-theme-secondary-orange));
        --section-background: rgb(var(--v-theme-secondary-orange-light));
    }

    .primary-light {
        --section-border-color: rgb(var(--v-theme-story-outline));
        --section-background: rgb(var(--v-theme-primary-light));
    }

    .empty-sheet {
        border: 1px solid var(--section-border-color);
        background-color: var(--section-background);

        &.desktop {
            border-radius: 20px;
        }

        &.mobile {
            border-radius: 16px;
        }
    }

    .empty-container {
        border: 2px dashed var(--section-border-color);
        background: var(--section-background);

        &.desktop {
            padding: 32px 40px;
            border-radius: 16px;
        }

        &.mobile {
            padding: 32px 20px;
            border-radius: 12px;
        }
    }

    .next-icon {
        text-align: center;
        color: rgb(var(--v-theme-story-outline));
        font-size: 120.09px;
        font-weight: 100;
        line-height: 120.09px;
        word-wrap: break-word;

        background: var(
            --Primary-Yellow-gradient,
            linear-gradient(167deg, #ffc32b 0%, #fb8625 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
    }

    .next-card-icon-container {
        font-size: 20.09px;
        font-weight: 100;
        line-height: 120.09px;
        word-wrap: break-word;
        cursor: pointer;
    }
</style>
