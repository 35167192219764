<template>
    <app-tutorial section-name="Personal Strengths" action-text="Start">
        <template v-slot:title>What makes you, you?</template>
        <template #default="{ step }">
            <div v-if="step === 1">
                <p>
                    We are all unique and bring to the workforce innate personal strengths and
                    traits. These are normally things we have been all our life - our personal
                    characteristics that others find hard to replicate. Reflect back on what makes
                    you intrinsically unique in this activity!
                </p>
            </div>
        </template>
    </app-tutorial>
</template>
