<template>
    <ProfileCard title="Languages">
        <v-card-text class="pa-0 text-wrap text-paragraph-md" v-if="userInputs">
            <v-autocomplete
                class="mb-2"
                label="Primary language"
                variant="outlined"
                :chips="true"
                :auto-select-first="true"
                :clearable="true"
                :items="primaryLanguages"
                v-model="userInputs.primary_language"
                :rules="[requiredRule('Primary language')]"
                :return-object="false"
                item-title="title"
                item-value="value"
                validate-on="input"
            />

            <v-autocomplete
                label="Other languages"
                variant="outlined"
                :chips="true"
                :auto-select-first="true"
                :clearable="true"
                :multiple="true"
                :items="otherLanguages"
                v-model="userInputs.other_languages"
                :return-object="false"
                item-title="title"
                item-value="value"
            />
        </v-card-text>
    </ProfileCard>
</template>

<script setup lang="ts">
    import { useUserProfileStore } from '@/store/profile/store';
    import { storeToRefs } from 'pinia';
    import {
        familiarLanguageOptions,
        otherLanguageOptions,
    } from '@/components/profile/userInputOptions';
    import { requiredRule } from '@/components/profile/rules';
    import { computed, watch } from 'vue';
    import ProfileCard from '@/components/profile/ProfileCard.vue';

    const store = useUserProfileStore();

    const { userInputs } = storeToRefs(store);

    const primaryLanguages = computed(() => [...familiarLanguageOptions, ...otherLanguageOptions]);

    watch(
        () => userInputs.value.primary_language,
        (newValue) => {
            if (newValue && userInputs.value.other_languages?.includes(newValue)) {
                userInputs.value.other_languages = userInputs.value.other_languages.filter(
                    (language: string) => language !== newValue,
                );
            }
        },
    );

    const otherLanguages = computed(() => {
        const options = [...familiarLanguageOptions, ...otherLanguageOptions];

        if (userInputs.value.primary_language) {
            return options.filter((option) => option.value !== userInputs.value.primary_language);
        }

        return options;
    });
</script>
